<template>
    <div class="comment">
        <!-- 评论 -->
        <header-information title='收到的评论' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <!-- 评论列表 -->
        <chat-list :list='chatList' @putItem='goCommentDetail($event)' @oriData='viewOriginalPost($event)'
            @reply='quickReply($event)'>
        </chat-list>

        <!-- 弹出框 -->
        <van-popup v-model="show" position="top" :style="{ height: '30%' }">
            <div class="po_input">
                <van-field v-model="recontent" type="textarea" :placeholder="'回复' + name" />
            </div>
            <div class="po_btn">
                <van-button round type="info" size="mini" @click="submit()">发布</van-button>
            </div>
        </van-popup>
    </div>
</template>
<script>
    import chatList from '../components/chatList'
    import headerInformation from '../components/headerInformation'
    export default {
        name: 'comment',
        components: {
            headerInformation,
            chatList,
        },
        data() {
            return {
                chatList: [],
                commentShow: true,
                show: false,
                recontent: '',
                name: '',
                cid: '',
                uid: ''
            }
        },
        mounted() {
            this.getCommentList()
        },
        methods: {
            /**收到别人评论我的 */
            getCommentList() {
                this.$get('/api/getCommentList')
                    .then((response) => {
                        this.chatList = response.data
                        // console.log('----chatlist',this.chatList)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**评论详情  mcid != 0 是帖子回复的页面，否则是帖子详情的页面*/
            goCommentDetail(item) {
                // console.log('--输出', item.mcid, item.cid, item.postsID)
                if (item.mcid != 0) {
                    this.$router.push({
                        path: '/commentdetail',
                        query: {
                            cid: item.cid,
                            postsID: item.postsID
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/postdetail',
                        query: {
                            postsID: item.postsID
                        }
                    })
                }
                // console.log('评论详情', item)
            },
            /**查看原帖 */
            viewOriginalPost(item) {
                // console.log('item---',item)
                this.$router.push({
                    path: '/postdetail',
                    query: {
                        postsID: item.postsID
                    }
                })
            },
            /**快速回复 */
            quickReply(item) {
                this.show = true
                this.name = item.name
                this.cid = item.cid
                this.uid = item.uid
                this.mcid = item.mcid
                this.postsID = item.postsID
                console.log('--item', item, '---this.mcid', this.mcid, '--this.cid', this.cid)
            },
            /**发布评论  (条件暂时不需要传)
             * type:1 一级回复(this.cid != 0 && this.mcid === 0) 
             * type：2 回复评论 (mcid != 0)
            */
            submit() {
                // console.log('---cid' ,this.cid,'--mcid',this.mcid)
                this.$post('/api/createPostsCommentReply', {
                        // cid: this.cid != 0 && this.mcid === 0 ? this.cid : this.mcid,
                        cid:this.cid,
                        to_uid: this.uid,
                        content: this.recontent,
                        // type: this.cid != 0 && this.mcid == 0 ? 1 : 2
                        type:2
                    })
                    .then((response) => {
                        this.$toast(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                this.content = ''
                this.show = false
            }

        }
    }
</script>
<style scoped>
    .comment {
        width: 100%;
        height: 2.75rem;
    }

    .po_input>>>.van-field--min-height .van-field__control {
        min-height: 9rem !important;
    }

    .po_btn {
        float: right;
        margin-right: 1rem;
        margin-top: -1rem;
        /* background-color: aqua; */
    }

    .po_btn>>>.van-button--mini {
        padding: 0 1.5rem !important;
    }
</style>