<template>
    <div>
        <!-- 公告详情页 -->
        <header-information title='公告详情' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <div class="recommend_content">
            <div class="user_avatar">
                <img src="../assets/logo.png" alt="">
                <ul>
                    <li>{{AnnouncementList[0].account_name}}</li>
                    <li>{{getDate(AnnouncementList[0].utime * 1000)}}</li>
                </ul>
            </div>
            <div class="user_content">
                <div class="user_content_title">
                    <van-tag plain type="primary" color="rgba(255, 150, 11, 0.9)">
                        官方
                    </van-tag>
                    <span class="user_content_infor">{{AnnouncementList[0].title}}</span>
                </div>
                <div class="user_content_text">
                    {{AnnouncementList[0].content}}
                </div>
                <ul>
                    <li v-for="(imgUrl,index) in AnnouncementList[0].content_extra" :key="index">
                        <img :src="imgUrl" alt="" @click="viewPic(imgUrl)">
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>
<script>
    import {
        ImagePreview
    } from 'vant';
    import moment from 'moment'
    import headerInformation from '../components/headerInformation'
    export default {
        name: 'noticeDetail',
        components: {
            headerInformation,
            [ImagePreview.Component.name]: ImagePreview.Component,
        },
        data() {
            return {
                page: 1,
                AnnouncementList: [{
                    account_name: '',
                    content: '',
                    title: '',
                    content_extra: []
                }],
                noticeId: '',
                imgViewShow: false,
                images: [],
            }
        },
        mounted() {
            this.noticeId = this.$route.query.noticeId;
            this.gameID = localStorage.getItem('gameID');
            this.getAnnouncementList();
        },
        methods: {
            /**获取公告 */
            getAnnouncementList() {
                this.$get('/api/getAnnouncementList', {
                        gameid: this.gameID,
                        page: this.page
                    })
                    .then((response) => {
                        this.AnnouncementList = response.data.list.filter(item => item.id == this.noticeId);
                        // console.log('----this.AnnouncementList',this.AnnouncementList)
                        // 当未查找到对应的字段时候  赋值默认值
                        if (this.AnnouncementList.length === 0) {
                            taht.AnnouncementList = [{
                                account_name: '',
                                content: '',
                                title: '',
                                content_extra: []
                            }];
                        }
                    })
            },
            getDate(time) {
                return moment(time).format('MM-DD')
            },
            /**查看大图*/
            viewPic(url) {
                // console.log('---查看大图', url)
                ImagePreview({
                    images: [url],
                    closeable: true,
                    showIndex: false
                });
            },
        }
    }
</script>
<style scoped>
    .recommend_content {
        position: relative;
        width: 100%;
        height: auto;
        margin: 0 auto;
        padding: 1rem 0;
    }

    .user_avatar {
        display: flex;
        justify-content: flex-start;
        margin-left: .94rem;
    }

    .user_avatar img {
        width: 1.75rem;
        height: 1.75rem;
        border: solid 0.06rem #ffffff;
        border-radius: 50%;
    }

    .recommend_content ul {
        color: black;
        font-size: 0.63rem;
    }

    .recommend_content ul li:last-child {
        color: #999999;
    }

    .user_content {
        margin: .81rem auto;

    }

    .user_content_title {
        display: flex;
        align-items: center;
        margin-left: .94rem;
    }

    .user_content_infor {
        display: inline-block;
        width: 17.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 600;
        margin-left: .3rem;
    }

    .user_content_text {
        font-size: .81rem;
        color: #494949;
        /* margin-left: .94rem; */
        margin: .5rem 0 0 .94rem;
    }

    .user_content img {
        width: 100%;
        height: auto;
    }

    .user_content ul {
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: .4rem;
    }

    .user_content ul li {
        margin: 0 .94rem;
        width: 100%;
    }

    .tag>>>.van-tag {
        color: #67b6ff;
        font-size: 0.81rem;
        padding: 0 .63rem;
        line-height: 1.38rem;
        margin-left: .94rem;
    }
</style>