<template>
    <div class="post">
        <!-- 评论详情 -->
        <header-information title='帖子回复' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <!-- <dynamic :actionPick='false' :List="commentDetailList" :imgList='imgList'></dynamic> -->

        <div class="recommend_content">
            <div class="user_avatar">
                <img :src=" commentDetailList.avatar_url !='' ? commentDetailList.avatar_url : ''" alt="">
                <ul style="margin-left:.5rem;">
                    <li>{{commentDetailList.name != '' ? commentDetailList.name : ''}}</li>
                    <li>{{getDate(commentDetailList.ctime * 1000)}}</li>
                </ul>
            </div>
            <div class="user_content">
                <div class="user_content_title">
                    <span class="user_content_infor">{{commentDetailList.content}}</span>
                </div>
                <ul>
                    <li v-for="(imgUrl,index) in commentDetailList.content_extra" :key="index">
                        <img :src="imgUrl" alt="">
                    </li>
                </ul>
            </div>
            <div class="action_pick">
                <div style="font-size:0.41rem;color:#999999;">{{getDate(commentDetailList.ctime * 1000)}}</div>
                <div>
                    <van-icon v-if="!commentDetailList.is_nice" class="iconfont" class-prefix="icon" name="dianzan"
                        @click="postPickAction(commentDetailList)" />
                    <van-icon v-else class="iconfont yi" class-prefix="icon" name="yidianzan"
                        @click="postPickAction(commentDetailList)" />
                    <span>{{commentDetailList.nices_num}}</span>
                </div>
            </div>
        </div>

        <!-- 评论 -->
        <div class="comment">
            <div class="new_dynamic" @click="showSort()">
                <span>全部回复 {{this.reply_info.length}}</span>
                <!-- <span v-show="hot">
                    <van-icon name="sort" />热门评论
                </span>
                <span v-show="!hot">
                    <van-icon name="sort" />最新评论
                </span> -->
            </div>
            <!-- 评论内容 -->
            <div v-for="(item,index) in reply_info" :key="index">
                <div v-if="reply_info != ''">
                    <div class="comment_list">
                        <div class="comment_info">
                            <img :src="item.reply_avatar_url != '' ? item.reply_avatar_url : ''" alt="">
                            <span>{{item.reply_name}}</span>
                            <van-tag type="success" color="#5aead2">lv1</van-tag>
                        </div>
                        <div class="comment_up">
                            <span>{{index+1}}楼</span>
                            <!-- 暂时关闭 -->
                            <!-- <span>
                                <van-icon class="iconfont" class-prefix="icon" name="more" />
                            </span> -->
                        </div>
                    </div>
                    <div class="comment_post" @click="reply(item,2)">
                        {{item.content}}
                    </div>
                    <div class="comment_time">
                        <div>{{getDate(item.ctime * 1000)}}</div>
                        <div>
                            <van-icon v-if="!item.is_nice" class="iconfont" class-prefix="icon" name="dianzan"
                                @click="postPickAction(item)" />
                            <van-icon v-else class="iconfont yi" class-prefix="icon" name="yidianzan"
                                @click="postPickAction(item)" />
                            <span style="margin-right: .66rem;">{{item.nices_num}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 底部悬浮-->
            <div class="release">
                <div>
                    <van-field readonly placeholder="说点什么~" @click="onClick(commentDetailList,1)" />
                </div>
                <div class="release_img">
                    <!-- <van-icon class="iconfont" class-prefix="icon" name="pinglun" />
                    <span>20</span>
                    <van-icon class="iconfont" class-prefix="icon" name="shoucang" />
                    <span>20</span> -->

                    <van-icon v-if="!commentDetailList.is_nice" class="iconfont" class-prefix="icon" name="dianzan"
                        @click="postPickAction(commentDetailList)" />
                    <van-icon v-else class="iconfont yi" class-prefix="icon" name="yidianzan"
                        @click="postPickAction(commentDetailList)" />
                    <span>{{commentDetailList.nices_num}}</span>
                </div>
            </div>

            <!-- 弹出框 -->
            <van-popup v-model="show" position="top" :style="{ height: '30%' }">
                <div class="po_input">
                    <!-- <van-field v-model="value" type="textarea" placeholder="回复" /> -->
                    <!-- <van-field v-model="value" type="textarea" :placeholder="'回复' + commentDetailList.name" /> -->
                    <!-- <van-field v-model="value" type="textarea" :placeholder="'回复' + this.reply_name" /> -->
                    <van-field v-model="value" type="textarea" :placeholder="this.clickType == 1 ? '回复' + commentDetailList.name : '回复' + this.reply_name" />
                </div>
                <div class="po_btn">
                    <van-button round type="info" size="mini" @click="submit()">发布</van-button>
                </div>
            </van-popup>

            <van-overlay :show="showMask">
                <!-- 登录 -->
                <logindialogbox :showDialog='showDialog' @putRegister='handleRegister' @Close='handleClose'>
                </logindialogbox>
                <!-- 注册 -->
                <rgisterdialogbox :showeRgister='showeRgister' @Login='handleLogin' @Close='handleClose'>
                </rgisterdialogbox>
            </van-overlay>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'
    import headerInformation from '../components/headerInformation'
    import dynamic from '../components/dynamic'
    import rgisterdialogbox from '../components/registerDialogBox'
    import logindialogbox from '../components/loginDialogBox'
    export default {
        name: 'postDetail',
        components: {
            headerInformation,
            dynamic,
            logindialogbox,
            rgisterdialogbox,
        },
        data() {
            return {
                commentDetailList: {
                    nices_num: '',
                    is_nice: ''
                },
                showDialog: false,
                showMask: false, //遮罩层
                showeRgister: false,
                imgList: [],
                page: 1,
                createTime: '',
                hot: true,
                show: false,
                value: '',
                commentList: [],
                reply_info: [],
                isMyNIce: 0,
                postsID: '',
                reply_name:'',
                clickType:''

            }
        },
        created() {
            this.cid = this.$route.query.cid
            this.postsID = this.$route.query.postsID
        },
        mounted() {
            this.getCommentDetail()
        },
        methods: {
            /**注册 */
            handleRegister() {
                this.showeRgister = !this.showeRgister
            },
            /**关闭弹窗 */
            handleClose() {
                this.showDialog = false
                this.showeRgister = false
                this.showMask = false
            },
            /**登录 */
            handleLogin() {
                this.showeRgister = !this.showeRgister
                this.showDialog = true
            },
            /**获取评论详情信息 */
            getCommentDetail() {
                this.$post('/api/getCommentDetail', {
                        cid: this.cid
                    })
                    .then((response) => {
                        this.commentDetailList = response.data
                        this.reply_info = response.data.reply_info
                        // console.log(this.commentDetailList)
                        // console.log(this.reply_info)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /** 排序 */
            showSort() {
                this.hot = !this.hot
            },
            getDate(time) {
                return moment(time).format("YYYY-MM-DD");
            },
            onClick(item,clickType) {
                /** 判断是否登录 */
                // console.log('---item',item,clickType)
                this.clickType = clickType
                let hasToken = localStorage.getItem('token');
                if (hasToken === '') {
                    this.showDialog = true
                    this.showMask = true
                } else {
                    this.show = true
                    this.cid = item.id
                    this.to_uid = item.api_uid
                    // console.log('---this.to_uid', item.name, item.id, this.to_uid)
                }
            },
            /**回复评论 */
            submit() {
                this.$post('/api/createPostsCommentReply', {
                        cid: this.cid,
                        to_uid: this.to_uid,
                        content: this.value,
                        type: 1
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.$toast(response.data)
                            this.getCommentDetail()
                            this.value = ''
                            this.show = false
                            // console.log(response)
                        } else {
                            /**登录过期的时候调起登录弹窗*/
                            this.showDialog = true
                            this.showMask = true
                        }

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**回复评论的点赞
             * 一级评论type :1
             * 二级评论type:2
             */
            postPickAction(item) {
                this.$post('/api/createNiceComment', {
                        cId: item.id,
                        type: !!item.cid ? 2 : 1
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            response.data == '-1' ? item.nices_num += 1 : item.nices_num -= 1
                            response.data == '-1' ? item.is_nice = 1 : item.is_nice = 0
                        } else {
                            /**登录过期的时候调起登录弹窗*/
                            this.showDialog = true
                            this.showMask = true
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**回复二级评论*/
            reply(item,clickType) {
                // console.log('----回复评论', item,clickType)
                this.show = true
                this.reply_name = item.reply_name
                this.clickType = clickType
                this.to_uid = item.reply_uid
                this.cid = item.cid
            }

        }
    }
</script>
<style scoped>
    .post {
        width: 100%;
        height: 2.75rem;
    }

    .comment {
        margin-top: .5rem;
        width: 100%;
        height: auto;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 1rem 1rem 5rem;
    }

    .new_dynamic {
        position: relative;
        width: 100%;
        height: 2rem;
        background-color: #ffffff;
        font-size: 0.63rem;
        color: #494949;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .new_dynamic span:first-child {
        font-size: 0.81rem;
        color: #999999;
    }

    .comment_list {
        display: flex;
        justify-content: space-between;
        margin-top: .97rem;
    }

    .new_dynamic>>>.van-icon {
        margin: .2rem;
    }

    .dynamic_select {
        position: absolute;
        right: .88rem;
        top: 18rem;
        width: 6.25rem;
        height: 5.88rem;
        border: .05rem solid #dddddd;
        background-color: #ffffff;
        border-radius: .3rem;
        z-index: 1;
        text-align: center;
        line-height: 1.88rem;
        font-size: 0.63rem;
    }

    .comment_list img {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 50%;
    }

    .comment_info,
    .comment_time div:last-child {
        display: flex;
        align-items: center;

    }

    .comment_info span {
        margin-right: .25rem;
    }

    .comment_up span {
        margin-left: 1.25rem;
        font-size: 0.63rem;
        color: #999999;
    }

    .comment_post {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 1rem 2rem;
        font-size: 0.81rem;
        white-space: pre-wrap;
    }

    .comment_time {
        display: flex;
        justify-content: space-between;
        margin-left: 2rem;
    }

    .comment_time div:first-child {
        font-size: 0.81rem;
        color: #999999;
    }

    .comment_content {
        background-color: #f8f8f8;
        border-radius: 0.31rem;
        margin-top: 1rem;
    }

    .uphost {
        color: #1890ff;
        font-size: 0.81rem;
    }

    .release {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 2.75rem;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 111;
    }

    .release img {
        width: 1.38rem;
        height: 1.38rem;
        margin-left: .5rem;
    }

    .release span {
        color: #999999;
    }

    .release_img {
        width: 35%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 1rem;
    }


    .recommend_content {
        position: relative;
        width: 100%;
        height: auto;
        margin: 0 auto;
        padding: 1rem 0;
        background-color: #ffffff;
    }

    .recommend_content::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 95%;
        height: .03rem;
        background-color: #dddddd;
        margin: 0 .94rem;
    }



    .user_avatar {
        display: flex;
        justify-content: flex-start;
        margin-left: .94rem;
    }

    .user_avatar img {
        width: 1.75rem;
        height: 1.75rem;
        border: solid 0.06rem #ffffff;
        border-radius: 50%;
    }

    .recommend_content ul {
        color: black;
        font-size: 0.63rem;
    }

    .recommend_content ul li:last-child {
        color: #999999;
    }

    .user_content {
        margin: .81rem auto;

    }

    .user_content_title {
        display: flex;
        align-items: center;
        margin-left: .94rem;
    }

    .user_content_infor {
        display: inline-block;
        width: 17.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 600;
        margin-left: .3rem;
    }

    .user_content_text {
        font-size: .81rem;
        color: #494949;
        margin-left: .94rem;
    }

    .user_content img {
        width: 6.75rem;
        height: 6.75rem;
        border-radius: 0.31rem;
    }

    .user_content ul {
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
    }

    .user_content ul li {
        margin-left: .94rem;
    }

    .action_pick {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: .94rem;
        padding: 0 1rem;
    }

    .action_pick div:last-child {
        display: flex;
        align-items: center;
    }

    .tag>>>.van-tag {
        color: #67b6ff;
        font-size: 0.81rem;
        padding: 0 .63rem;
        line-height: 1.38rem;
        margin-left: .94rem;
    }

    .activeClass {
        display: none;
    }

    .action_pick img,
    .comment_time img,
    .release_img img {
        width: 1.25rem;
        height: 1.06rem;
    }

    .po_input>>>.van-field--min-height .van-field__control {
        min-height: 9rem !important;
    }

    .po_btn {
        float: right;
        margin-right: 1rem;
    }

    .po_btn>>>.van-button--mini {
        padding: 0 1.5rem !important;
    }

    .yi {
        color: #1890ff;
    }
</style>