<template>
    <div class="box" :style="{backgroundColor:bgcolor,color:wordcolor}">
        <div class="box_left" @click="handleClick()">
            <van-icon name="arrow-left" size='1rem' />返回
        </div>
        <div class="box_center">{{title}}</div>
        <div class="box_right">
            <slot name='right'></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'headerInformation',
        props:{
            title:{
                type:String,
                default:''
            },
            bgcolor:{
                type:String,
                default:'#1890ff'
            },
            wordcolor:{
                type:String,
                default:'#ffffff'
            }
        },
        data(){
            return{

            }
        },
        methods:{
            handleClick(){
                this.$router.go(-1)
            }
        }
    }
</script>
<style scoped>
    .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 2.75rem;
    }

    .box_left {
        margin-left: .94rem;
    }

    .box_left .van-icon {
        top: .2rem;
    }

    .box_center {
        font-size: 1.13rem;
    }

    .box_right {
        margin-right: 2rem;
    }
</style>