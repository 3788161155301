<template>
    <div class="thumbsup">
        <!-- 点赞 -->
        <header-information title='收到的赞' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <!-- 点赞列表 -->
        <nice-list :list='niceList' @putItem='getPostDetail($event)'></nice-list>
    </div>
</template>
<script>
    import chatList from '../components/chatList'
    import headerInformation from '../components/headerInformation'
    import niceList from '../components/niceList.vue'
    export default {
        name: 'thumbsUp',
        components: {
            headerInformation,
            chatList,
            niceList
        },
        data() {
            return {
                niceList:[],
            }
        },
        mounted(){
            this.getNiceList();
        },
        methods:{
             /**获取点赞 */
            getNiceList(){
                this.$get('/api/getNiceList')
                .then((response)=>{
                    this.niceList = response.data
                    // console.log(this.niceList)
                })
                .catch((error)=>{
                    console.log(error)
                })
            },
            /**帖子详情 */
            getPostDetail(item){
                // console.log(item)
                this.$router.push({
                    path:'/postdetail',
                    query:{
                        postsID:item.postsID
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .thumbsup {
        width: 100%;
        height: 2.75rem;
    }
</style>