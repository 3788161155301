<template>
    <div class="box">
        <!-- 我的 -->
        <div class="user_head">
            <div class="user_avatar">
                <div class="user_avatar_left">
                    <img :src="information.avatar_url ? information.avatar_url : ''" alt="">
                    <ul>
                        <li>{{information.nickname}}</li>
                        <li>
                            <van-tag type="success" color="#5aead2">lv1</van-tag>
                        </li>
                    </ul>
                </div>
                <div style="width: 5rem;height: 5rem;text-align: right;line-height: 5rem;"
                    @click="goPersonalHomePage()">
                    <van-icon name="arrow" />
                </div>
            </div>
            <div class="pick_item">
                <ul>
                    <li @click="enterFanse()"><span>{{information.fansNumber}}</span>粉丝</li>
                    <li @click="enterGZ()"><span>{{information.gzNumber}}</span>关注</li>
                    <li @click="enterPost()"><span>{{information.postsNumber}}</span>发帖</li>
                    <li @click="enterThumbsup()"><span>{{information.likesNumber}}</span>获赞</li>
                </ul>
            </div>
        </div>
        <!-- <div class='grade_information'>
            <p class="grade_information_text">社区等级</p>
            <div class="button_box">
                <div class="grade_button" v-for="(item,index) in 4" :key="index">
                    <img src="../assets/1.png" alt="">
                    <span>萌将风云·Lv.1</span>
                </div>
            </div>
            <p class="grade_information_text">勋章<span class="medal_counts">(0/20)</span></p>
            <div class="medal_contain"></div>
        </div> -->
        <!-- 其他信息 -->
        <div class='grade_information'>
            <van-cell icon="shop-o" is-link v-for="(item,index) in pickList" :key="index" @click="goActionPick(item)">
                {{item.title}}
                <span v-if="item.path==='myphone'" class="phone_number">{{mobile}}已绑定</span>
            </van-cell>
        </div>
        <bottom-nav></bottom-nav>
    </div>
</template>
<script>
    import bottomNav from '../components/bottomNav'
    export default {
        name: 'mine',
        components: {
            bottomNav,
        },
        data() {
            return {
                pickList: [
                    //     {
                    //     title: '我的角色',
                    //     path: 'myrole'
                    // }, {
                    //     title: '我的礼包',
                    //     path: 'mygiftpack'
                    // },
                    {
                        title: '我的收藏',
                        path: 'mycollection'
                    }, {
                        title: '我的评论',
                        path: 'personalhomepage'
                    }, {
                        title: '绑定手机号',
                        path: 'myphone'
                    }, {
                        title: '设置',
                        path: 'setting'
                    }
                ],
                information: {
                    avatar_url: '',
                    nickname: '',
                    idcard_number: '',
                    describe: '',
                    fansNumber: '',
                    gzNumber: '',
                    postsNumber: '',
                    likesNumber: '',
                    accountid: ''
                },
                mobile: '',
                userId: '',
                token: ''
            }
        },
        mounted() {
            this.userId = localStorage.getItem('userId')
            let token = localStorage.getItem('token')
            this.getPersonal()
            /**未登录时，重定向页面并调起登录弹窗 */
            if (!token) {
                this.$router.replace({
                    path: '/home',
                    query: {
                        login: 'true'
                    }
                })
            }
        },
        methods: {
            goActionPick(item) {
                console.log('---item', item)
                if (item.path === 'personalhomepage') {
                    this.$router.push({
                        path: '/' + item.path,
                        query: {
                            uid: this.information.accountid,
                            from: 'comment'
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/' + item.path
                    })
                }
            },
            /**个人主页 */
            goPersonalHomePage() {
                this.$router.push({
                    path: '/personalhomepage',
                    query: {
                        uid: this.information.accountid
                    }
                })
            },
            /**获取用户信息 */
            getPersonal() {
                this.$get(`/api/getUserInfo/${this.userId}`)
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.information = response.data
                            if (response.data.mobile !== '') {
                                let m2 = response.data.mobile.substring(0, 3) + '****' + response.data.mobile
                                    .substring(7, 11)
                                this.mobile = m2
                            }
                            // console.log('---mobile--',this.information)

                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            enterGZ() {
                this.$router.push({
                    path: '/mygz'
                })
            },
            enterFanse() {
                this.$router.push({
                    path: '/myfans'
                })
            },
            enterPost() {
                this.$router.push({
                    path: '/personalhomepage',
                    query: {
                        uid: this.information.accountid
                    }
                })
            },
            enterThumbsup() {
                this.$router.push({
                    path: '/thumbsup'
                })
            }
        }
    }
</script>
<style scoped>
    .user_head {
        background-color: #ffffff;
        padding: 1rem 1rem 0;
    }

    .user_avatar {
        width: 100%;
        height: 4.78rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1.03rem;
        border-bottom: .03rem solid #dddddd;
    }

    .user_avatar_left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .user_avatar ul {
        padding: 0;
        margin-left: .75rem;
    }

    .user_avatar ul li:first-child {
        margin-bottom: .65rem;
    }

    .user_avatar img {
        width: 3.75rem;
        height: 3.75rem;
        border: solid 0.06rem #ffffff;
        border-radius: 50%;
        box-shadow: 0rem 0.13rem 0.25rem 0rem rgba(0, 0, 0, 0.3);
    }

    .pick_item {
        height: 2.81rem;
        width: 100%;
        background-color: #ffffff;
        line-height: 2.81rem;
    }

    .pick_item ul {
        display: flex;
        justify-content: space-around;
    }

    .pick_item ul li {
        font-size: 0.63rem;
    }

    .pick_item ul li span {
        font-size: 1.06rem;
        color: #494949;
        margin-right: .2rem;
    }

    .grade_information {
        width: 100%;
        height: auto;
        /* background-color: rgb(236, 175, 175); */
        background-color: #ffffff;
        margin-top: .5rem;
    }

    .grade_information_text {
        display: inline-block;
        text-indent: .38rem;
        margin-left: .94rem;
        font-weight: bold;
        font-size: 1.06rem;
        border-left: .25rem solid #1890ff;
    }

    .button_box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0 .94rem;
    }

    .grade_button {
        width: 7.63rem;
        height: 1.88rem;
        background-color: #ffffff;
        border-radius: 0.31rem;
        border: .05rem solid #dddddd;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 .63rem .63rem 0;
        overflow: hidden;
    }

    .grade_button img {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 0.25rem;
    }

    .grade_button span {
        font-size: 0.81rem;
    }

    .medal_counts {
        margin-left: .75rem;
    }

    .medal_contain {
        width: 100%;
        height: 5.13rem;
    }

    .medal_contain::before {
        content: '';
        position: absolute;
        left: .94rem;
        width: 92%;
        height: .03rem;
        background-color: #dddddd;
    }

    .grade_information>>>.van-cell {
        border-bottom: .03rem solid #dddddd;
        padding: .80rem;
    }

    /* 
    .grade_information .van-cell:last-child {
        padding-bottom: 4rem;
    } */


    .phone_number {
        color: #53d600;
        float: right;
        font-size: 0.81rem;
    }
</style>