import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/page/index'
import home from '@/page/home'
import community from '@/page/community'
import information from '@/page/information'
import mine from '@/page/mine'
import comment from '@/page/comment'
import thumbsup from '@/page/thumbsUp'
import systemmessage from '@/page/systemMessage'
import myrole from '@/page/myRole'
import mygiftpack from '@/page/myGiftPack'
import mycollection from '@/page/myCollection'
import myphone from '@/page/myPhone'
import setting from '@/page/setting'
import personalhomepage from '@/page/personalHomePage'
import editdata from '@/page/editData'
import search from '@/page/search'
import myplacard from '@/page/myPlacard'
import postdetail from '@/page/postDetail'
import commentdetail from '@/page/commentDetail'
import mygz from '@/page/myGZ'
import myfans from '@/page/myFans'
import noticedetail from '@/page/noticeDetail'
import allnotice from '@/page/allnotice'



const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    routes: [{
            path: '/',
            component: index
        }, {
            path: '/home',
            component: home,
        }, {
            path: '/community',
            component: community,
            meta: {
                keepAlive: true, //需要缓存的页面
                title: '社区'
            }
        }, {
            name: 'information',
            path: '/information',
            component: information,
            meta: {
                keepAlive: true, //需要缓存的页面
                title: '消息'
            }
        }, {
            name: 'mine',
            path: '/mine',
            component: mine,
        }, {
            path: '/comment',
            component: comment
        }, {
            path: '/thumbsup',
            component: thumbsup
        },
        // 系统消息，暂时不需要
        // {
        //     path: '/systemmessage',
        //     component: systemmessage
        // },
        {
            path: '/myrole',
            component: myrole
        }, {
            path: '/mygiftpack',
            component: mygiftpack
        }, {
            path: '/mycollection',
            component: mycollection
        }, {
            path: '/myphone',
            component: myphone
        }, {
            path: '/setting',
            component: setting
        }, {
            path: '/personalhomepage',
            component: personalhomepage
        }, {
            path: '/editdata',
            component: editdata
        }, {
            path: '/search',
            component: search
        },
        {
            path: '/myplacard',
            component: myplacard
        },
        {
            path: '/postdetail',
            component: postdetail
        }, {
            path: '/commentdetail',
            component: commentdetail
        }, {
            path: '/mygz',
            component: mygz
        },
        {
            path: '/myfans',
            component: myfans
        }, {
            path: '/noticedetail',
            component: noticedetail
        },
        {
            path: '/allnotice',
            component: allnotice
        }
    ]
})

export default router