<template>
    <div class="box">
        <div class="user_avatar" v-for="(item,index) in list" :key="index">
            <!-- 聊天列表 -->
            <div class="user_avatar_left" @click="handleClick(item)">
                <img :src="item.avatarUrl != '' ? item.avatarUrl : ''" alt="">
                <ul class="comment">
                    <li>
                        <!-- <span>收到</span> -->
                        <span style="color:#1890ff;">{{item.name != '' ? item.name : ''}} : </span>
                        <span>{{item.content}}</span>
                    </li>
                    <li class="item">
                        <span>回复了{{item.mcid == 0 ? '我的帖子' : '我的评论'}}：{{item.originalContent != '' ? item.originalContent : '空'}}</span>
                    </li>
                </ul>
            </div>
            <div class="pick_action">
                <span @click="putData(item)">查看原帖</span>
                <span @click="putReply(item)">快速回复
                    <van-icon class="iconfont" class-prefix="icon" name="pinglun" />
                </span>
            </div>
            <span class="time">{{getDate(item.time * 1000)}}</span>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'
    export default {
        name: 'chatList',
        props: {
            list: {
                type: Array,
                default () {
                    return []
                }
            },
        },
        data() {
            return {

            }
        },
        methods: {
            handleClick(item) {
                this.$emit('putItem', item)
                // console.log('--收到评论mid',item.mcid)
            },
            getDate(item) {
                return moment(item).format('MM-DD');
            },
            putData(item) {
                this.$emit('oriData', item)
            },
            putReply(item) {
                this.$emit('reply', item)
                // console.log('--快速回复',item)
            }
        }
    }
</script>
<style scoped>
    /* .user_avatar:first-child {
        padding-top: 1rem;
    } */

    .user_avatar {
        width: 100%;
        height: 6.31rem;
        /* display: flex;
        justify-content: space-between;
        align-items: center; */
        border-bottom: .03rem solid #dddddd;
        background-color: #ffffff;
    }

    .user_avatar_left {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0 0 .94rem;
    }

    .user_avatar ul {
        padding: 0;
        margin-left: .75rem;
    }

    .user_avatar ul li {
        font-size: 0.81rem;
        color: #494949;
        max-width: 12rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }


    .pick_action span:first-child {
        margin-right: .5rem;
    }

    .pick_action {
        display: flex;
        justify-content: flex-end;
        padding-top: 1rem;
        font-size: .75rem;
        color: #999999;
        margin-right: 1rem
    }

    .item {
        margin-top: .5rem;
    }

    .item,
    .time {
        font-size: 0.81rem;
        color: #999999;
    }

    .time {
        float: right;
        padding-right: .94rem;
        margin-top: -4.5rem;
    }

    .user_avatar img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
    }

    .comment .com_img {
        width: 1.25rem;
        height: 1.06rem;
        vertical-align: middle;
        margin-left: .2rem;

    }
</style>