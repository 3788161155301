<template>
    <div class="box">
        我的礼包
    </div>
</template>
<script>
export default {
    name:'myGiftPack'
}
</script>
<style scoped>
.box{
    width:100%;
    height: 5.5rem;
    background-color: red;
}
</style>