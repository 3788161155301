<template>
    <div class="box">
        <!-- 我的角色 -->
        <header-information title='绑定角色' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <van-tabs v-model="active">
            <van-tab title="官方列表绑定"></van-tab>
            <van-tab title="角色码绑定"></van-tab>
        </van-tabs>
        <div class="container">
            <!-- 官方列表绑定 -->
            <van-form @submit="onSubmit" v-if="active===0">
                <!-- 区服 -->
                <van-field readonly clickable label="区服" right-icon="arrow-down" :value="service" placeholder="选择区服"
                    @click="PickerService = true">
                </van-field>
                <van-popup v-model="PickerService" round position="bottom">
                    <van-picker show-toolbar :columns="columns" @cancel="PickerService = false"
                        @confirm="handleClickService">
                    </van-picker>
                </van-popup>
                <!-- 角色 -->
                <van-field readonly clickable label="角色" right-icon="arrow-down" :value="role" placeholder="选择角色"
                    @click="PickerRole = true">
                </van-field>
                <van-popup v-model="PickerRole" round position="bottom">
                    <van-picker show-toolbar :columns="columnsRole" @cancel="PickerRole = false"
                        @confirm="handleClickRole">
                    </van-picker>
                </van-popup>
            </van-form>
            <!-- 角色码绑定 -->
            <div v-if="active===1">
                <van-cell-group>
                    <van-field v-model="roleNumber" label="角色码" placeholder="请进入游戏获得角色码" />
                </van-cell-group>
                <div class="explain_box">
                    <p class="explain">绑定说明</p>
                    <p class="text">请进入游戏，点击【个人空间】-【系统设置】-【角色绑定码】，
                        在游戏内获取角色绑定码，在本页面输入即可完成绑定
                    </p>
                </div>
            </div>
            <div style="margin: .5rem;">
                <van-button block type="info" native-type="submit">绑定</van-button>
            </div>
        </div>
    </div>
</template>
<script>
    import headerInformation from '../components/headerInformation'
    export default {
        name: 'myRole',
        components: {
            headerInformation,
        },
        data() {
            return {
                active: 0,
                service: '',
                role: '',
                PickerService: false,
                PickerRole: false,
                columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
                columnsRole: ['角色1', '角色2'],
                roleNumber: ''
            }
        },
        methods: {
            handleClickService(value) {
                this.service = value;
                this.PickerService = false;
            },
            handleClickRole(value) {
                this.role = value;
                this.PickerRole = false;
            },
            onSubmit(values) {
                // console.log('submit', values);
            },
        }
    }
</script>
<style scoped>
    .container {
        min-height: 35rem;
        background-color: #ffffff;
    }

    .box>>>.van-tabs__line {
        background-color: #1890ff;
    }

    .box>>>.van-cell__value {
        border: .05rem solid #dddddd;
        height: 2.13rem;
        line-height: 2.13rem;
        border-radius: .63rem;
        padding-left: .5rem;
    }

    .box>>>.van-cell:first-child {
        padding-top: 1.5rem;
    }

    .box>>>.van-field__label {
        width: 3.25rem;
        line-height: 2.25rem;
        margin-right: 0;
        text-align: center;
    }

    .box>>>.van-field__right-icon {
        margin-right: .3rem;
    }

    .box>>>.van-cell::after {
        border-bottom: none;
    }

    .box>>>.van-button--block {
        width: 16.44rem;
        margin: 0 auto;
        border-radius: .5rem;
    }

    /* 初始化如何显示该颜色 */
    .box>>>.van-tab--active:hover {
        color: #1890ff;
    }

    .explain_box {
        padding: 0 1rem 0 1.5rem;
    }

    .explain {
        font-size: 0.81rem;
        color: #494949;
    }

    .text {
        font-size: 0.81rem;
        color: #999999;
    }
</style>