<template>
    <div class="box">
        <!-- 编辑资料 -->
        <div class="head">
            <div class="pic_box">
                <img :src="avatar_url != '' ? avatar_url : ''" alt="">
                <div class="text">
                    <van-uploader multiple :after-read="onRead">
                        点击修改头像
                    </van-uploader>
                </div>
            </div>
        </div>
        <van-form @submit="onSubmit">
            <div class="personal_information">
                <van-field v-model="nickname" name="昵称" label="昵称" placeholder="昵称" />
                <!-- <van-field label="昵称" placeholder="昵称" :value="information.nickname" /> -->
                <van-field name="radio" label="性别">
                    <template #input>
                        <van-radio-group v-model="radio" direction="horizontal">
                            <van-radio name="1">男
                                <template #icon="props">
                                    <img class="img-icon" :src="props.checked ? isboy : boy" />
                                </template>
                            </van-radio>
                            <van-radio name="2">女
                                <template #icon="props">
                                    <img class="img-icon" :src="props.checked ? isgirl : girl" />
                                </template>
                            </van-radio>
                            <van-radio name="0">保密
                                <template #icon="props">
                                    <img class="img-icon" :src="props.checked ? issecrecy : secrecy" />
                                </template>
                            </van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
            </div>
            <span class="signature">
                个性签名
                <span class="signature_text">（最多输入48个字符）</span>
            </span>
            <div class="personal_information signature_content">
                <!-- :value="information.describe"  -->
                <van-field v-model="describe" rows="3" autosize type="textarea" maxlength="48" placeholder="请输入留言"
                    show-word-limit>
                </van-field>
            </div>
            <div style="margin:1.5rem;text-align:center;">
                <van-button round plain type="info" native-type="submit" size="small">保存</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
    export default {
        name: 'editData',
        data() {
            return {
                radio: '',
                describe: '',
                nickname: '',
                avatar_url: '',
                fileList: [],
                boy: require('../assets/boy.png'),
                isboy: require('../assets/checkboy.png'),
                girl: require('../assets/girl.png'),
                isgirl: require('../assets/checkgirl.png'),
                secrecy: require('../assets/secrecy.png'),
                issecrecy: require('../assets/checksecrecy.png'),
                information: {},
                userId: '',
                imgShow: true
            }
        },
        mounted() {
            this.userId = localStorage.getItem('userId')
            this.getPersonal()
        },
        methods: {
            /**获取用户信息 */
            getPersonal() {
                this.$get(`/api/getUserInfo/${this.userId}`)
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.information = response.data
                            this.radio = (response.data.sex).toString()
                            this.nickname = response.data.nickname
                            this.describe = response.data.describe
                            this.avatar_url = response.data.avatar_url
                            // console.log('---', this.information)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**修改用户信息 */
            onSubmit(values) {
                this.$post('/api/updateUserInfo', {
                        nickname: this.nickname,
                        // avatar_url: this.avatar_url || this.fileList[0],
                        avatar_url: this.avatar_url,
                        describe: this.describe,
                        sex: this.radio
                    })
                    .then((response) => {
                        this.$router.go(-1)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**上传图片*/
            onRead(file) {
                // this.imgShow = false
                var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
                formData.append('file', file.file); //接口需要传的参数
                this.$postImg('/api/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => { //将formDate文件上传到阿里云服务器，会返回图片地址
                        // let str = response.data;
                        // this.fileList.push(str); //将图片地址存入一个数组中
                        this.avatar_url = response.data
                    });
            },
        }
    }
</script>
<style scoped>
    .box {
        width: 100%;
        min-height: 39rem;
        background-color: #ffffff;
    }

    .head {
        width: 100%;
        height: 9rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pic_box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .text {
        font-size: 0.81rem;
        color: #1890ff;
        margin-top: .63rem;
    }

    .head img {
        width: 3.75rem;
        height: 3.75rem;
        box-shadow: 0rem 0.13rem 0.25rem 0rem rgba(0, 0, 0, 0.3);
        border: solid 0.13rem #ffffff;
        border-radius: 50%;
    }

    .personal_information {
        margin: 0 .94rem;
        background-color: #ffffff;
        border-radius: 0.31rem;
        border: solid .09rem #dddddd;
        overflow: hidden;
    }

    .signature {
        font-size: 0.81rem;
        color: #949494;
        margin: 1.25rem;
        display: inline-block;
    }

    .signature_text {
        color: #999999;
        font-size: 0.81rem;
    }

    .signature_content>>>.van-field__word-limit {
        margin-top: 4rem;
    }

    .van-button--small {
        width: 3.5rem;
    }

    .img-icon {
        height: 1.25rem;
    }
</style>