<template>
    <div class="notice">
        <!-- 公告 -->
        <header-information title='公告' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <!-- <section v-if="AnnouncementList && AnnouncementList.name"> -->
        <section v-if="AnnouncementList && AnnouncementList.length">
            <div class="recommend_content" v-for="(item,index) in AnnouncementList" :key="index"
                @click="handleDetail(item)">
                <div class="user_avatar">
                    <img src="../assets/logo.png" alt="">
                    <ul>
                        <li>{{item.account_name}}</li>
                        <li>{{getDate(item.utime * 1000)}}</li>
                    </ul>
                </div>
                <div class="user_content">
                    <div class="user_content_title">
                        <van-tag plain type="primary" color="rgba(255, 150, 11, 0.9)">
                            官方
                        </van-tag>
                        <span class="user_content_infor">{{item.title}}</span>
                    </div>
                    <div class="user_content_text">
                        {{item.content}}
                    </div>
                    <ul>
                        <li v-for="(imgUrl,index) in item.content_extra" :key="index">
                            <img :src="imgUrl" alt="">
                        </li>
                    </ul>
                </div>

            </div>
        </section>
    </div>
</template>
<script>
    import moment from 'moment'
    import headerInformation from '../components/headerInformation'
    export default {
        name: 'allnotice',
        components: {
            headerInformation,
        },
        data() {
            return {
                page: 1,
                AnnouncementList: []
            }
        },
        mounted() {
            this.gameID = localStorage.getItem('gameID')
            this.getAnnouncementList()
        },
        methods: {
            /**获取公告 */
            getAnnouncementList() {
                this.$get('/api/getAnnouncementList', {
                        gameid: this.gameID,
                        page: this.page
                    })
                    .then((response) => {
                        this.AnnouncementList = response.data.list
                    })
            },
            getDate(time) {
                return moment(time).format('MM-DD')
            },
            /**公告详情页 */
            handleDetail(item) {
                // console.log(item.id)
                this.$router.push({
                    path: '/noticedetail',
                    query: {
                        noticeId: item.id
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .notice {
        width: 100%;
        height: 2.75rem;
    }

    .recommend_content {
        position: relative;
        width: 100%;
        height: auto;
        margin: 0 auto;
        padding: 1rem 0;
    }

    .user_avatar {
        display: flex;
        justify-content: flex-start;
        margin-left: .94rem;
    }

    .user_avatar img {
        width: 1.75rem;
        height: 1.75rem;
        border: solid 0.06rem #ffffff;
        border-radius: 50%;
    }

    .recommend_content ul {
        color: black;
        font-size: 0.63rem;
    }

    .recommend_content ul li:last-child {
        color: #999999;
    }

    .user_content {
        margin: .81rem auto;

    }

    .user_content_title {
        display: flex;
        align-items: center;
        margin-left: .94rem;
    }

    .user_content_infor {
        display: inline-block;
        width: 17.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 600;
        margin-left: .3rem;
    }

    .user_content_text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        font-size: .81rem;
        color: #494949;
        line-height: 1.3rem;
        margin: .5rem 0 0 .94rem;
    }

    .user_content img {
        width: 6.75rem;
        height: 6.75rem;
        border-radius: 0.31rem;
        object-fit: cover;
        background-size: cover;
    }

    .user_content ul {
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: .4rem;
    }

    .user_content ul li {
        margin-left: .94rem;
    }

    .tag>>>.van-tag {
        color: #67b6ff;
        font-size: 0.81rem;
        padding: 0 .63rem;
        line-height: 1.38rem;
        margin-left: .94rem;
    }
     []{
        display:none !important ;
    }
</style>