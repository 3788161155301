<template>
    <div class="box">
        <!-- 换绑手机号 -->
        <header-information title='换绑手机号' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <div class="binding_phone">
            已绑定手机号:
            <span>{{mobile}}</span>
        </div>
        <van-form @submit="onSubmit">
            <van-field v-model="codeOld" center clearable left-icon="smile-o" placeholder="请输入验证码">
                <template #button>
                    <van-button size="small" type="info" native-type="button" @click="handleoldCode(oldphone)"
                        :class="oldcount > 0 ? 'count' : ''">{{oldcount > 0 ? oldcount + 's' : '发送验证码'}}
                    </van-button>
                </template>
            </van-field>
            <van-field v-model="newphone" clearable type="info" left-icon="smile-o" placeholder="请输入新手机号码" />
            <van-field v-model="codeNew" center clearable left-icon="smile-o" placeholder="请输入验证码">
                <template #button>
                    <van-button size="small" type="info" native-type="button" @click="handlenewCode(newphone)"
                        :class="newcount > 0 ? 'count' : ''">{{newcount > 0 ? newcount + 's' : '发送验证码'}}
                    </van-button>
                </template>
            </van-field>
            <div style="margin: .5rem;">
                <van-button block type="info" native-type="submit">确定</van-button>
                <van-button block type="info" plain @click="handleClose()">返回</van-button>
            </div>
        </van-form>

    </div>
</template>
<script>
    import headerInformation from '../components/headerInformation'
    export default {
        name: 'myPhone',
        components: {
            headerInformation,
        },
        data() {
            return {
                oldphone: '',
                newphone: '',
                mobile: '',
                codeNew: '',
                codeOld: '',
                oldcount: '',
                newcount: '',
                oldtimer: null,
                newtimer: null,
                currPhone: ''
            }
        },
        mounted() {
            this.getMobile()
        },
        methods: {
            handleClose() {
                this.$router.go(-1)
            },
            getMobile() {
                let sideBar = localStorage.getItem('phoneNumber')
                this.oldphone = localStorage.getItem('phoneNumber')
                if (typeof sideBar !== 'undefined' && sideBar !== null) {
                    this.mobile = sideBar.substring(0, 3) + '****' + sideBar.substring(7, 11)
                }
            },
            /**获取旧手机验证码 */
            handleoldCode(phoneNumber) {
                if (phoneNumber) {
                    const TIME_COUNT = 60;
                    this.$get(`/api/sendSms/${phoneNumber}`).then((response) => {
                        if (response.msg === 'success') {
                            if (!this.oldtimer) {
                                this.oldcount = TIME_COUNT;
                                this.oldtimer = setInterval(() => {
                                    if (this.oldcount > 0 && this.oldcount <= TIME_COUNT) {
                                        this.oldcount--;
                                    } else {
                                        clearInterval(this.oldtimer);
                                        this.oldtimer = null;
                                    }
                                }, 1000)
                            }
                        }
                    })
                } else {
                    this.$toast('请输入手机号');
                }
            },
            /**获取新手机验证码 */
            handlenewCode(phoneNumber) {
                if (phoneNumber) {
                    const TIME_COUNT = 60;
                    this.$get(`/api/sendSms/${phoneNumber}`).then((response) => {
                        if (response.msg === 'success') {
                            if (!this.newtimer) {
                                this.newcount = TIME_COUNT;
                                this.newtimer = setInterval(() => {
                                    if (this.newcount > 0 && this.newcount <= TIME_COUNT) {
                                        this.newcount--;
                                    } else {
                                        clearInterval(this.newtimer);
                                        this.newtimer = null;
                                    }
                                }, 1000)
                            }
                        }
                    })
                }
                else{
                    this.$toast('请输入手机号');
                }
            },
            /**换绑手机号*/
            onSubmit() {
                // console.log('submit', this.newphone);
                this.$post('/api/changeMobile', {
                        codeOld: this.codeOld,
                        mobileNew: this.newphone,
                        codeNew: this.codeNew
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            //换绑手机号成功以后 去修改 localstorage 下的手机号
                            localStorage.setItem('phoneNumber', this.newphone)
                            this.$router.go(-1)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
        }
    }
</script>
<style scoped>
    .binding_phone {
        width: 100%;
        height: 2.38rem;
        background-color: #f7f7f7;
        font-size: 0.81rem;
        text-indent: 1.5rem;
        line-height: 2.38rem;
        color: #ff0000;
    }

    .van-button--normal {
        margin-top: 1rem;
    }

    .count {
        width: 5rem;
        background-color: #c0bcbc;
        border: .5rem solid #c0bcbc;
    }
</style>