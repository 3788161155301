<template>
    <div class="box">
        <!-- 搜索 -->
        <div class="box_left" @click="handleClick()">
            <van-icon name="arrow-left" />
        </div>
        <van-field v-model="value" placeholder="搜索" />
    </div>
</template>
<script>
    export default {
        name: 'search',
        data() {
            return {
                value: ''
            }
        },
        methods: {
            handleClick() {
                this.$router.go(-1)
            }
        }
    }
</script>
<style scoped>
    .box {
        width: 100%;
        height: 2.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .box>>>.van-icon {
        margin-left: .5rem;
    }

    .box>>>.van-cell {
        height: 1.75rem;
        line-height: 1.75rem;
        margin: 0 .56rem 0 .3rem;
        padding: 0 0 0 .5rem;
    }
</style>