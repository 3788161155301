<template>
    <div v-show="showDialog" class="dialog">
        实名认证弹窗
         <span class="close" @click="handleClose()">X</span>
    </div>
</template>
<script>
    export default {
        name: 'certification',
        props: {
            showDialog: {
                type: Boolean,
                default: false
            },
        },
        methods:{
              /**关闭弹窗 */
            handleClose() {
                this.$emit('Close')
            }
        }
    }
</script>
<style scoped>
    .dialog {
        position: fixed;
        left: 0;
        right: 0;
        top: 29%;
        margin: 0 auto;
        width: 19.44rem;
        height: 16.69rem;
        background-color: #ffffff;
        border-radius: 0.63rem;
    }
      .close {
        position: absolute;
        top: .45rem;
        right: .85rem;
        cursor: pointer;
        color: rgb(167, 167, 167);
    }
</style>