<template>
    <div class="box">
        <div class="user_avatar" v-for="(item,index) in list" :key="index"  @click="handleClick(item)">
            <!-- 聊天列表 -->
            <div class="user_avatar_left">
                <img :src="item.avatarUrl != '' ? item.avatarUrl : ''" alt="">
                <ul>
                    <li>
                        <span>{{item.name != '' ? item.name : ''}}</span>
                    </li>
                    <li>{{item.describe === null ? '暂无个性签名' : item.describe}}</li>
                </ul>
            </div>
            <van-button round color="#dddddd" plain size="mini" v-show="btnshow" @click="clickCancel(item)">已关注</van-button>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'
    export default {
        name: 'gzList',
        props: {
            list: {
                type: Array,
                default () {
                    return []
                }
            },
            btnshow:{
                type:Boolean,
                default:true
            }
        },
        data() {
            return {

            }
        },
        methods: {
            handleClick(item) {
                this.$emit('putItem', item.uid)
            },
            getDate(item) {
                return moment(item).format('MM-DD')
            },
            clickCancel(item){
                this.$emit('Cancel',item)
                // console.log(item)
            }
        }
    }
</script>
<style scoped>
    .user_avatar:first-child {
        padding-top: 1rem;
    }

    .user_avatar {
        width: 100%;
        height: 3.16rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: .03rem solid #dddddd;
        background-color: #ffffff;
    }

    .user_avatar_left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: .94rem;
    }

    .user_avatar ul {
        padding: 0;
        margin-left: .75rem;
    }

    .user_avatar ul li:first-child {
        font-size: 0.81rem;
        color: #494949;
    }

    .user_avatar ul li:last-child,
    .time {
        font-size: 0.81rem;
        color: #999999;
    }

    .time {
        padding-right: .94rem;
    }

    .user_avatar img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
    }

    .user_avatar>>>.van-button--mini {
        height: 1.63rem;
        padding: 0 1.3rem;
        margin-right: .5rem;
    }
</style>