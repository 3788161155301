<template>
    <div>
        <!-- 个人主页 -->
        <header-information title='个人主页' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <div class="head"></div>
        <div class="user_information">
            <img :src="information.avatar_url != '' ? information.avatar_url : ''" alt="">
            <van-button v-if="userId === accountID" plain round type="info" size="mini" class="round_btn"
                @click="goEditData()">编辑资料</van-button>
            <ul class="information_ul">
                <li class="item1">{{information.nickname != '' ? information.nickname :''}}</li>
                <li class="item">ID:{{information.idcard_number}}</li>
                <li class="item">{{information.describe != '' ? information.describe :'暂无个性签名~'}}</li>
                <li>
                    <ul class="pick_item">
                        <li><span>{{information.fansNumber}}</span>粉丝</li>
                        <li><span>{{information.gzNumber}}</span>关注</li>
                        <li><span>{{information.postsNumber}}</span>发帖</li>
                        <li><span>{{information.likesNumber}}</span>获赞</li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- 勋章 -->
        <!-- <div class="medal_box">
            <p class="grade_information_text">勋章<span class="medal_counts">(0/20)</span></p>
            <div class="medal_contain"></div>
        </div> -->
        <div class="other_contain">
            <van-tabs class="van_tabs" v-model="active" @click="handleClickTab(active)">
                <van-tab v-for="(item,index) in tabList" :key="index" :name="index">
                    <template #title>{{item}}</template>
                </van-tab>
            </van-tabs>
            <!-- 发帖 -->
            <div v-if="active===0">
                <!-- 下拉加载 上拉刷新 -->
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh(active)">
                    <van-list v-model="loading" :finished="finished" :immediate-check='false'
                        :finished-text="finishedText" @load="onLoad(active)">
                        <dynamic class="personal" :deleteBtn='userId === accountID ? true : false' :Topping='false'
                            :List="recommendList" @putItem='goPostDetail($event)'
                            @putDelete='handleClickDelete($event)'>
                        </dynamic>
                    </van-list>
                </van-pull-refresh>
            </div>
            <!-- 评论 -->
            <div v-if="active===1">
                <comment-topic-list :commentList='commentList' @putItem='handleDelect($event)'
                    @goDetail='goPostDetail($event)' :deleteBtn='userId === accountID ? true : false'>
                </comment-topic-list>
            </div>
            <!-- 点赞 -->
            <div v-if="active===2">
                <!-- 下拉加载 上拉刷新 -->
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh(active)">
                    <van-list v-model="loading" :finished="finished" :immediate-check='false'
                        :finished-text="finishedText" @load="onLoad(active)">
                        <dynamic class="personal" :Topping='false' :List="niceedList" @putItem='goPostDetail($event)'>
                        </dynamic>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
    </div>
</template>
<script>
    import headerInformation from '../components/headerInformation'
    import dynamic from '../components/dynamic'
    import commentTopicList from '../components/commentTopicList'
    export default {
        name: 'personalHomePage',
        components: {
            dynamic,
            commentTopicList,
            headerInformation,
        },
        data() {
            return {
                tabList: ['发帖', '评论', '点赞'],
                // tabList: [{id:1,title:'发帖'}, {id:2,title:'评论'}, {id:3,title:'点赞'}],
                active: 0,
                recommendList: [],
                information: {
                    idcard_name: '',
                    idcard_number: '',
                    describe: '',
                    fansNumber: '',
                    gzNumber: '',
                    postsNumber: '',
                    likesNumber: '',
                    accountid: ''
                },
                commentList: [],
                niceedList: [],
                userId: '',
                deleteCommentId: '',
                gameID: '',
                loading: false,
                finished: false,
                finishedText: '',
                refreshing: false,
                page: 1,
                accountID: ''
            }
        },
        created() {
            // this.active = this.$route.query.index
            // console.log('----active')
            if (this.$route.query.from === 'comment') {
                this.active = 1;
            }
        },
        mounted() {
            // console.log('---',this.$route.query.index)
            this.gameID = localStorage.getItem('gameID')
            this.accountID = localStorage.getItem('userId')
            this.userId = this.$route.query.uid
            this.getPostListHomepage();
            this.getCommentedList();
            this.getNiceedList();
            this.getPersonal();
        },
        methods: {
            goEditData() {
                this.$router.push({
                    path: '/editdata'
                })
            },
            /**获取用户信息 */
            getPersonal() {
                this.$get(`/api/getUserInfo/${this.userId}`)
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.information = response.data
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**获取帖子信息 待修改 换成getPostListHomepage这个接口*/
            getPostListHomepage() {
                this.$post('/api/getPostListHomepage', {
                    accountID: this.userId,
                    gameID: this.gameID,
                    page: this.page,
                }).then((response) => {
                    if (response.msg === 'success') {
                        if (this.page === 1) {
                            this.recommendList = response.data.list
                        } else {
                            this.recommendList = this.recommendList.concat(response.data.list)
                        }
                        this.loading = false;
                        this.page++;
                        //如果请求返回的数据为空，就不请求
                        if (response.data.list.length == 0) {
                            this.finished = true;
                            this.finishedText = '--- 没有更多了 ---'
                            return
                        }
                    }
                })
            },
            /**获取评论 */
            getCommentedList() {
                this.$get('/api/getCommentedList', {
                        uid: this.userId
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.commentList = response.data
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**获取点赞 */
            getNiceedList() {
                this.$post('/api/getNiceedList', {
                        page: this.page,
                        uid: this.userId
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            if (this.page === 1) {
                                this.niceedList = response.data.list
                            } else {
                                this.niceedList = this.niceedList.concat(response.data.list)
                            }
                            this.loading = false;
                            this.page++;
                            //如果请求返回的数据为空，就不请求
                            if (response.data.list.length == 0) {
                                this.finished = true;
                                this.finishedText = '--- 没有更多了 ---'
                                return
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**上拉加载*/
            onLoad(active) {
                if (active === 0) {
                    this.getPostListHomepage()
                }
                if (active === 2) {
                    this.getNiceedList()
                }
            },
            /**下拉刷新 */
            onRefresh(active) {
                this.$toast('已加载最新')
                // this.page = 1
                // this.refreshing = false
                this.finished = false; // 清空列表数据
                this.loading = true; // 重新加载数据, 将 loading 设置为 true，表示处于加载状态
                this.page = 1
                this.refreshing = false
                if (active === 0) {
                    this.getPostListHomepage()
                }
                if (active === 2) {
                    this.getNiceedList()
                }
            },
            /**切换tab*/
            handleClickTab(active) {
                this.loading = false
                this.finished = false
                this.page = 1
                if (active === 0) {
                    this.getPostListHomepage()
                }
                if (active === 1) {
                    this.getCommentedList()
                }
                if (active === 2) {
                    this.getNiceedList()
                }
            },
            /** 跳转详情页*/
            goPostDetail(id) {
                this.$router.push({
                    path: '/postdetail',
                    query: {
                        postsID: id
                    }
                })
            },
            /**删除评论 */
            handleDelect(item) {
                this.$dialog.confirm({
                        title: '提示',
                        message: '确定要删除该评论吗',
                    })
                    .then(() => {
                        console.log(item)
                        this.$post('/api/deleteMyComment', {
                                id: item.cid || item.mcid,
                                type: item.cid != 0 ? 1 : 2
                            })
                            .then((response) => {
                                if (response.msg == 'success') {
                                    this.$toast(response.data)
                                    this.getCommentedList()
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                    .catch(() => {})
            },
            /**删除帖子 */
            handleClickDelete(item) {
                this.$dialog.confirm({
                        title: '提示',
                        message: '确定要删除该帖子吗',
                    })
                    .then(() => {
                        this.$post('/api/deleteMyPosts', {
                                id: item
                            })
                            .then((response) => {
                                if (response.msg === 'success') {
                                    this.$toast(response.data)
                                    this.page = 1
                                    this.getPostListHomepage()
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                    .catch(() => {})
            },
        }
    }
</script>
<style scoped>
    .head {
        width: 100%;
        height: 7.63rem;
        background-image: linear-gradient(180deg, #71bbff 0%, #1890ff 100%);
    }

    .user_information {
        position: relative;
        height: 9.12rem;
        width: 100%;
        background-color: #ffffff;
    }

    .user_information img {
        position: absolute;
        top: -2rem;
        left: 1rem;
        width: 3.75rem;
        height: 3.75rem;
        box-shadow: 0rem 0.13rem 0.25rem 0rem rgba(0, 0, 0, 0.3);
        border: solid 0.13rem #ffffff;
        border-radius: 50%;
    }

    .information_ul {
        padding: 2.5rem 0 0 1rem;
        height: 7rem;
    }

    .information_ul .item1,
    .pick_item li span {
        font-size: 1.06rem;
        color: #494949;
        margin-bottom: .3rem;
    }

    .information_ul .item {
        font-size: .81rem;
        margin-bottom: .3rem;
        color: #999999;
    }

    .pick_item {
        display: flex;
        justify-content: flex-start;
    }

    .pick_item li {
        margin-right: 1.03rem;
        color: #999999;
        font-size: 0.63rem;
    }

    .round_btn {
        position: absolute;
        right: 1rem;
        top: .75rem;
    }

    .medal_box {
        margin-top: .5rem;
        background-color: #ffffff;
    }

    .grade_information_text {
        display: inline-block;
        text-indent: .38rem;
        margin-left: .94rem;
        font-weight: bold;
        font-size: 1.06rem;
        border-left: .25rem solid #1890ff;
    }

    .medal_counts {
        margin-left: .75rem;
    }

    .medal_contain {
        width: 100%;
        height: 5.13rem;
    }

    .medal_contain::before {
        content: '';
        position: absolute;
        left: .94rem;
        width: 92%;
        height: .03rem;
        background-color: #dddddd;
    }

    .other_contain {
        width: 100%;
        /* 修改 */
        height: 15rem;
        background-color: #ffffff;
        margin-top: .5rem;
    }

    .van_tabs>>>.van-tabs__line {
        background-color: #1890ff;
    }

    .van_tabs>>>.van-tab {
        border-bottom: solid 0.03rem #dddddd;
    }

    .personal>>>.user_content_text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }
</style>