<template>
    <div v-show="showDialog" class="dialog">
        <!-- 登录页面 -->
        <van-tabs v-model="active" @change="handleChangeTab">
            <van-tab title="账号登录"></van-tab>
            <van-tab title="短信登录"></van-tab>
        </van-tabs>
        <van-form>
            <van-field v-if="active===0" v-model="userNumber" name="用户名" placeholder="请输入手机号/账号">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="yonghu"></van-icon>
            </van-field>
            <van-field v-if="active===0" v-model="password" type="password" name="密码" placeholder="请输入密码">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="mima"></van-icon>
            </van-field>
            <van-field v-if="active===1" v-model="phoneNumber" name="手机号" placeholder="请输入手机号">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="shouji"></van-icon>
            </van-field>
            <van-field v-if="active===1" v-model="sms" center clearable placeholder="请输入手机验证码">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="safe"></van-icon>
                <template #button>
                    <van-button size="small" type="info" native-type="button" :class="oldcount > 0 ? 'count' : ''"
                        @click="handleCode(phoneNumber)">{{oldcount > 0 ? oldcount + 's' : '发送验证码'}}</van-button>
                </template>
            </van-field>
            <van-field name="checkbox">
                <template #input>
                    <van-checkbox v-if="active===0" v-model="mimacheck">记住用户密码</van-checkbox>
                    <van-checkbox v-if="active===1" v-model="namecheck">记住用户名</van-checkbox>
                </template>
            </van-field>
            <div style="margin: 0 1rem .5rem;">
                <van-button v-if="active===0" block type="info" native-type="submit" @click="Login()">立即登录</van-button>
                <van-button v-if="active===1" block type="info" native-type="submit" @click="loginSms()">立即登录
                </van-button>
            </div>
            <div style="text-align: center;">还没有账号？<span style="color:#ff8600;" @click="handleClick()">立即注册</span></div>
        </van-form>
        <span class="close" @click="handleClose()">X</span>
    </div>
</template>
<script>
    export default {
        name: 'loginDialogBox',
        props: {
            showDialog: {
                type: Boolean,
                // default: false
            },
            showMask: {
                type: Boolean,
                // default: false
            }
        },
        data() {
            return {
                active: 0,
                userNumber: '',
                phoneNumber: '',
                password: '',
                sms: '',
                userId: '',
                oldcount: '',
                oldtimer: null,
                mimacheck: true,
                namecheck: true,
            }
        },
        created() {
            this.getInformation()
        },
        mounted() {
            //登录时不需要携带token
            // localStorage.setItem('token', '');
            // this.Login();
        },
        methods: {
            /**切换tab页面 */
            handleChangeTab(index) {
                this.active = index
            },
            handleClick() {
                this.$emit('putRegister')
            },
            handleClose() {
                this.$emit('Close')
            },
            /**账户密码 登录 */
            Login() {
                if (this.mimacheck) {
                    localStorage.setItem('username', this.userNumber)
                    localStorage.setItem('password', this.password)
                } else {
                    localStorage.removeItem('username')
                    localStorage.removeItem('password')
                }
                this.$post(`/api/login`, {
                        phoneNumber: this.userNumber,
                        password: this.password,
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            localStorage.setItem('token', response.data.token); //将请求到的token存放到localStorage
                            localStorage.setItem('userId',response.data.uid);
                            this.userId = response.data.uid
                            this.$emit('Close');
                            this.getPersonal();
                        } else {
                            this.$toast(response.msg)
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            /**短信登录 */
            loginSms() {
                if (this.namecheck) {
                    localStorage.setItem('userphone', this.phoneNumber)
                } else {
                    localStorage.removeItem('userphone')
                }
                this.$post(`/api/loginSms`, {
                        phoneNumber: this.phoneNumber,
                        code: this.sms,
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            localStorage.setItem('token', response.data.token); //将请求到的token存放到localStorage
                            localStorage.setItem('userId',response.data.uid)
                            this.userId = response.data.uid
                            this.$emit('Close');
                            this.getPersonal();
                        } else {
                            this.$toast(response.msg)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            },
            /**获取用户信息 */
            getPersonal() {
                this.$get(`/api/getUserInfo/${this.userId}`)
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.information = response.data
                            localStorage.setItem('phoneNumber', response.data.mobile);
                            localStorage.setItem('userId', response.data.accountid)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**获取验证码 */
            handleCode(phoneNumber) {
                if (phoneNumber) {
                    const TIME_COUNT = 60;
                    this.$get(`/api/sendSms/${phoneNumber}`).then((response) => {
                        if (response.msg === 'success') {
                            if (!this.oldtimer) {
                                this.oldcount = TIME_COUNT;
                                this.oldtimer = setInterval(() => {
                                    if (this.oldcount > 0 && this.oldcount <= TIME_COUNT) {
                                        this.oldcount--;
                                    } else {
                                        clearInterval(this.oldtimer);
                                        this.oldtimer = null;
                                    }
                                }, 1000)
                            }
                        }
                    })
                } else {
                    this.$toast('请输入手机号');
                }
            },
            /**记住用户名 密码 登录账号 */
            getInformation() {
                this.userNumber = localStorage.getItem('username') || '',
                this.password = localStorage.getItem('password') || '',
                this.phoneNumber = localStorage.getItem('userphone') || ''
            }
        }
    }
</script>
<style scoped>
    .dialog {
        position: fixed;
        left: 0;
        right: 0;
        top: 29%;
        margin: 0 auto;
        width: 19.44rem;
        height: 16.69rem;
        background-color: #ffffff;
        border-radius: 0.63rem;
    }

    .dialog>>>.van-tabs__line {
        height: 0;
    }

    .dialog>>>.van-tab--active {
        border-bottom: .06rem solid #1890ff;
    }

    .close {
        position: absolute;
        top: .45rem;
        right: .85rem;
        cursor: pointer;
        color: rgb(167, 167, 167);
    }

    .dialog>>>.van-tabs--line .van-tabs__wrap {
        border-top-left-radius: .63rem;
        border-top-right-radius: .63rem;
    }

    .van-radio--horizontal {
        margin-right: 6rem;
    }

    .count {
        width: 5rem;
        background-color: #c0bcbc;
        border: .5rem solid #c0bcbc;
    }
</style>