<template>
    <div class="box">
        <ul class="box_ul">
            <li v-for="(item,index) in imgList" :key="index" @click="handleClick(index)">
                <router-link :to="'/' + item.path">
                    <img class="nav_img" :src="current===index ? item.checkPicUrl : item.picUrl">
                    <div>{{item.name}}</div>
                </router-link>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'bottomnav',
        data() {
            return {
                imgList: [{
                        name: '首页',
                        path: 'home',
                        picUrl: require('../assets/home.png'),
                        checkPicUrl: require('../assets/checkhome.png'),
                    },
                    {
                        name: '社区',
                        path: 'community',
                        picUrl: require('../assets/community.png'),
                        checkPicUrl: require('../assets/checkcommunity.png'),
                    },
                    {
                        name: '消息',
                        path: 'information',
                        picUrl: require('../assets/information.png'),
                        checkPicUrl: require('../assets/checkinformation.png'),
                    },
                    {
                        name: '我的',
                        path: 'mine',
                        picUrl: require('../assets/mine.png'),
                        checkPicUrl: require('../assets/checkmine.png'),
                    }
                ],
                current: 0,
            }
        },
        mounted() {
            this.initCurrent();
        },
        activated() {
            this.initCurrent();
        },
        methods: {
            initCurrent() {
                let path = this.$router.currentRoute.fullPath.replace('/', '');
                let index = this.imgList.findIndex(item => item.path === path);
                this.current = index === -1 ? 0 : index;
            },
            handleClick(index) {
                this.current = index
            },

        }
    }
</script>
<style scoped>
    .box {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3.13rem;
        font-size: 0.63rem;
        background-color: #ffffff;
        border: 0.03rem solid #ccc;
        color: #888888;
    }

    .box_ul {
        display: flex;
        justify-content: space-around;
        list-style-type: none;
        padding: 0;
        margin: 0.56rem auto;
    }

    .nav_img {
        width: 1.38rem;
        height: 1.38rem;
    }

    a {
        text-decoration: none;
        color: #888888;
    }

    .router-link-active {
        color: #1890ff;
    }
</style>