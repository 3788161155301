<template>
    <div class="placard">
        <!-- 创建帖子 -->
        <header-information :title="this.postsID ? '编辑帖子' : '发帖'" bgcolor='#ffffff' wordcolor='#494949'>
        </header-information>
        <!-- 标签 -->
        <div class="tag_category">
            <div class="tags">
                <span class="tag_text">分类:</span>
                <van-tag round type="primary" :plain='currentIndex == item.id ? false : true'
                    v-for="(item,index) in tagsList" :key="item.id" @click='handleClick(item,index)'>{{item.title}}
                </van-tag>
            </div>
        </div>
        <div class="title">
            <van-field v-model="title" rows="1" autosize type="textarea" placeholder="请输入30字以内的标题" show-word-limit />
        </div>

        <!-- document.execCommand 的方法 不建议使用-->
        <!-- <div v-if="this.postsID" id="editer" class='input_content' contenteditable="true" placeholder='话题内容，至少5个字'
            v-html="content">
        </div>
        <div v-else id="editer" class='input_content' contenteditable="true" placeholder='话题内容，至少5个字'>
        </div> -->

        <div v-if="this.postsID" @click="handleSelection" @input="handleSelection" class="input_content"
            contenteditable="true" placeholder='话题内容，至少5个字' v-html="content">
        </div>
        <div v-else @click="handleSelection" @input="handleSelection" class="input_content" contenteditable="true"
            placeholder='话题内容，至少5个字'>
        </div>


        <div class="bottom_box">
            <div class="pick_action" :value="tapTag" @click="showPopup = true">
                {{tapTag === '' ? '可选话题' : tapTag}} >
            </div>
            <div class="pick_action_btn">
                <div class="btn_img">
                    <!-- 暂时关闭 -->
                    <!-- <img src="../assets/emjoy.png" alt=""> -->
                    <!-- 上传图片 -->
                    <van-uploader multiple :after-read="onRead">
                        <img src="../assets/picture.png" alt="">
                    </van-uploader>
                </div>
                <van-button round type="info" @click="HandleSubmit()">发布</van-button>
            </div>
        </div>
        <div class="picker_box">
            <van-popup v-model="showPopup" round closeable position="bottom" :style="{ height: '80%' }">
                <van-form style="margin: 3.5rem 0;" @submit="onSubmit">
                    <div class="picker_tag">
                        <span>分类：</span>
                        <!-- <span v-show="currentShows" style="color:#999999;font-size:.61rem;">添加相关话题，吸引更多兴趣相投的朋友</span> -->
                        <span v-if="tapTag == ''" style="color:#999999;font-size:.61rem;">添加相关话题，吸引更多兴趣相投的朋友</span>
                        <div v-if="tapTag != ''" style="display:inline-block;">
                            <van-tag v-if="show" closeable size="medium" color='#54c9fe' text-color="#dbf4ff"
                                @close="close">
                                {{tapTag}}
                            </van-tag>
                        </div>
                    </div>
                    <van-search v-model="searchvalue" placeholder="搜索话题" @search="getTopicList()" />
                    <div class="action_btn">
                        <van-tag :text-color="currentTopicIndex == item.id ? '#dbf4ff' : '#999999'"
                            v-for="(item,topIndex) in toplicList" :key="topIndex" @click='handleTopClick(item,topIndex)'
                            :color="currentTopicIndex == item.id ? '#54c9fe' : '#f8f8f8'">{{item.title}}
                        </van-tag>
                    </div>
                    <div style="margin: .5rem;">
                        <van-button round block type="info" native-type="submit">选好了</van-button>
                    </div>
                </van-form>
            </van-popup>
        </div>
    </div>
</template>
<script>
    import headerInformation from '../components/headerInformation'
    export default {
        name: 'myPlacard',
        components: {
            headerInformation
        },
        data() {
            return {
                tagsList: [],
                currentIndex: '',
                currentTopicIndex: '',
                title: '',
                content: '',
                topValue: '可选话题',
                showPopup: false,
                // category: '',
                searchvalue: '',
                toplicList: [],
                fileList: [],
                show: true,
                // currentShows: true,
                topicID: '',
                classifyID: '',
                tapTag: '',
                gameID: '',
                postsID: '',
                detailList: [],
                selection: null,
                range: null,
                textNode: null,
                rangeStartOffset: null
            }
        },
        mounted() {
            this.postsID = this.$route.query.postsID
            this.gameID = localStorage.getItem('gameID')
            if (this.postsID) {
                this.getPostsList(this.postsID)
            }
            this.getTagsList()
            this.getTopicList()

        },
        methods: {
            handleClick(item, index) {
                this.classifyID = item.id
                this.currentIndex = item.id;
            },
            handleTopClick(item, index) {
                this.topicID = item.id
                this.currentTopicIndex = item.id;
                this.tapTag = item.title
                // this.currentShows = false
                // console.log(this.tapTag)
            },
            /**获取分类列表 */
            getTagsList() {
                this.$get(`/api/getClassifyList/${this.gameID}`).then((response) => {
                    this.tagsList = JSON.parse(response.data)
                    // console.log('---标签列表--', this.tagsList)
                })
            },
            /** 获取话题列表 */
            getTopicList() {
                this.$get('/api/getTopicListSearch', {
                    content: this.searchvalue,
                    gameId: this.gameID
                }).then((response) => {
                    this.toplicList = response.data
                    // console.log('---话题列表--',this.toplicList)
                })
            },
            /** 表单提交 */
            onSubmit(values) {
                // console.log('submit', values);
                this.showPopup = false
            },
            /**发布 -- 新增--编辑 */
            HandleSubmit() {
                var inputContent = document.querySelector('.input_content').innerHTML
                if (this.classifyID === '' && this.currentIndex === '') {
                    this.$toast('请选择分类标签')
                    return
                }
                if (this.title === '' || (this.title + '').trim().length > 30) {
                    this.$toast('请输入标题，30以内')
                    return
                }
                if (inputContent === '' || (inputContent + '').trim().length < 5) {
                    this.$toast('请输入话题内容，至少5个字')
                    return
                }
                if (this.topicID === '' && this.currentTopicIndex === '') {
                    this.$toast('请选择话题标签')
                    return
                }
                if (this.postsID) { //编辑
                    this.$post('/api/editPosts', {
                        postsID: this.postsID,
                        classifyID: this.classifyID || this.currentIndex,
                        topicID: this.topicID || this.currentTopicIndex,
                        gameID: this.gameID,
                        imgUrl: this.fileList,
                        title: this.title,
                        content: inputContent
                    }).then((response) => {
                        this.$toast(response.data)
                        this.$router.go(-1)
                    }).catch((error) => {
                        console.log(error)
                    })
                } else { //新增
                    this.$post('/api/createPosts', {
                        classifyID: this.classifyID || this.currentIndex,
                        topicID: this.topicID || this.currentTopicIndex,
                        gameID: this.gameID,
                        imgUrl: this.fileList,
                        title: this.title,
                        content: inputContent
                    }).then((response) => {
                        this.$toast(response.data)
                        this.$router.go(-1)
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                // console.log('--获取节点', JSON.stringify(inputContent))
            },
            /**上传图片*/
            onRead(file) {
                // console.log('---file', file); //file文件如下图
                var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
                formData.append('file', file.file); //接口需要传的参数
                this.$postImg('/api/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => { //将formDate文件上传到阿里云服务器，会返回图片地址
                        // console.log('上传', response);
                        let str = response.data;
                        // // this.fileList.push(str); //将图片地址存入一个数组中
                        // var editer = document.getElementById('editer');
                        // let ImagePath = str;
                        // editer.focus();
                        // document.execCommand('InsertImage', false, ImagePath);

                        const parseDom = this.parseDom(   
                            `<img src="${str}">`
                        )
                        this.range.insertNode(parseDom); // 在光标处插入dom
                        this.range.collapse(true); // 光标开始和光标结束重叠

                    })
                    .catch(err => {
                       console.log(error)
                    });
            },
            /**删除图片 */
            handleCancel(file) {
                this.fileList.splice(file, 1)
            },
            close() {
                this.show = false;
            },
            /**获取帖子详情信息 */
            getPostsList(postsID) {
                this.$post('/api/getPostDetail', {
                    postsID: postsID,
                }).then((response) => {
                    this.gameID = this.gameID
                    this.currentIndex = response.data.classifyInfo.id;
                    this.title = response.data.title
                    this.tapTag = response.data.topicInfo.title
                    this.currentTopicIndex = response.data.topicInfo.id
                    this.fileList = response.data.extra
                    this.content = response.data.content
                })
            },
            // 记录光标位置等 （debounce防抖提升性能）
            handleSelection() {
                this.selection = getSelection();
                this.range = this.selection.getRangeAt(0); // 光标对象
                this.textNode = this.range.startContainer;  // 获取光标对象的范围界定对象
                this.rangeStartOffset = this.range.startOffset;  // 获取光标位置
            },
            // 转dom对象
            parseDom(arg) {
                var objE = document.createElement("div");
                objE.innerHTML = arg;
                return objE.childNodes[0];
            }
        }
    }
</script>
<style scoped>
    .placard {
        width: 100%;
        height: 100%;
    }

    .tag_category {
        width: 100%;
        height: 2.75rem;
        background-color: #ffffff;
        line-height: 2.75rem;
        overflow-y: hidden;
        border-bottom: .05rem solid #dddddd;
    }

    .tag_text {
        display: inline-block;
        font-size: 0.81rem;
        margin-left: 1rem;
    }

    .tags>>>.van-tag--round {
        width: auto;
        height: 1.38rem;
        border-radius: 999px;
        display: inline-block;
        text-align: center;
        line-height: 1.38rem;
        margin: 0 .38rem;
    }

    .title>>>.van-field__word-limit {
        display: none;
    }

    .title>>>.van-cell {
        border-bottom: .05rem solid #dddddd;
    }

    .pick_action {
        font-size: 0.81rem;
        color: #999999;
        text-indent: 1rem;
    }

    .bottom_box {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 4.88rem;
        background-color: #ffffff;
    }

    .pick_action_btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 2.75rem;
        border-top: .05rem solid #dddddd;
        margin: .75rem 0 0;
    }

    .pick_action_btn img {
        width: 1.38rem;
        height: 1.38rem;
        display: inline-block;
        margin-left: 1rem;
    }

    .pick_action_btn>>>.van-button {
        margin-right: 1rem;
        height: 1.38rem;
    }

    .action_btn {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .action_btn>>>.van-tag {
        margin: 1.25rem 0 1.25rem 1rem;
    }

    .picker_box>>>.van-button {
        position: relative;
        bottom: -15rem;
    }

    .img-box {
        width: 90%;
        height: auto;
        display: flex;
        /* justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
        margin-bottom: 12rem; */
        flex-direction: column;
        margin-bottom: 12rem;
    }

    .cancel {
        position: absolute;
        margin-left: -1.5rem;
        color: white;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        line-height: 1.15rem;
        font-size: 1rem;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 0 1.5rem;
    }

    .image {
        /* max-width: 100%;
        height: auto; */
        /* position: relative;
        max-height: 53.333vw;
        max-width: 89.333vw; */
        margin: .5rem 0;
        display: inline-block;
        width: 100%;
        height: auto;
        flex-shrink: 0;
    }

    /* .grey{
        color:#f8f8f8;
    }
    .blue{
        color:blue
    } */
    .picker_tag {
        margin: 0 1.2rem;
    }

    /* .van-tag--medium{
        margin-left: .6rem;
    } */

    .input_content {
        width: 100%;
        height: 20rem;
        background-color: white;
        overflow-y: auto;
        padding: 1rem;
        box-sizing: border-box;
    }

    /*为空时显示 element attribute content*/
    .input_content:empty:before {
        content: attr(placeholder);
        /* element attribute*/
        /*content: 'this is content';*/
        color: #cbc9d6;
        /* padding: 1rem; */
        font-size: .8rem;
    }

    /*焦点时内容为空*/
    .input_content:focus:before {
        content: none;
    }
</style>