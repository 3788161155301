<template>
    <div class="box">
        <!-- 社区 -->

        <head class="head">
            <div class="box_left">{{title}}</div>
            <div class="box_right">
                <!-- 暂时关闭 -->
                <!-- <van-icon name="bookmark-o" size="1.6rem" />
                <van-icon name="search" size="1.6rem" @click="goSearch()" /> -->
            </div>
        </head>
        <!-- 图片轮播 -->
        <div class="picture_rotation">
            <van-swipe :loop='true' :autoplay="3000" width="21.56rem">
                <van-swipe-item v-for="(item,index) in AnnouncementList" :key="index">
                    <img :src="item.url" alt="">
                </van-swipe-item>
            </van-swipe>
        </div>
        <!-- 导航条 -->
        <!-- <van-tabs class="van_tabs" v-model="active" @click="handleClick(active)"> -->
        <div class="tabbox">
            <van-tabs class="van_tabs" v-model="active" @click="handleClick(active)">
                <van-tab v-for="(tab,index) in tabList" :title="tab.title" :key="index" :name="tab.id">
                </van-tab>
                <span class="action_down" @click="tabsBox=!tabsBox">
                    <van-icon name="arrow-down" />
                </span>
                <div class="tabs_box" v-show="tabsBox">
                    <!-- <span v-for="(tab,index) in tabList" :key="index" @click="handleClick(index)" style="margin-right:2rem;padding:.5rem 0;"> -->
                    <span v-for="(tab,index) in tabList" :key="index" @click="handleClick(index)"
                        style="margin-right:2rem;padding:.5rem 0;">
                        <a :class="currIndex === index ? 'activecolor' : ''">{{tab.title}}</a>
                    </span>
                    <span class="action_down up" @click="tabsBox=!tabsBox">
                        <van-icon name="arrow-up" />
                    </span>
                </div>
            </van-tabs>
        </div>
        <!-- 最新动态 -->
        <div class="new_dynamic" @click="sortList = true">
            <span>
                <van-icon name="sort" />{{sortTab}}
            </span>
        </div>
        <ul class="dynamic_select" v-show="sortList">
            <li v-for="(item,index) in sortData" :key="index" @click="showSort(item,index)">{{item}}</li>
        </ul>
        <!-- 动态 -->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" :immediate-check='false' :finished-text="finishedText"
                @load="onLoad">
                <!-- 置顶的帖子 -->
                <dynamic class="community" :tag='true' :List="topList" :imgList='topImgList'
                    @putItem='goPostDetail($event)'>
                </dynamic>
                <dynamic class="community" :tag='true' :List="recommendList" :imgList='imgList'
                    @putItem='goPostDetail($event)'>
                </dynamic>
            </van-list>
        </van-pull-refresh>

        <!-- 创建帖子 -->
        <div class="create_placard" @click="createPlacard()">
            <van-icon name="plus" size="2rem" />
        </div>
        <van-overlay :show="showMask">
            <!-- 登录 -->
            <logindialogbox :showDialog='showDialog' @putRegister='handleRegister' @Close='handleClose'>
            </logindialogbox>
            <!-- 注册 -->
            <rgisterdialogbox :showeRgister='showeRgister' @Login='handleLogin' @Close='handleClose'></rgisterdialogbox>
        </van-overlay>
        <bottom-nav></bottom-nav>
    </div>
</template>
<script>
    import bottomNav from '../components/bottomNav'
    import rgisterdialogbox from '../components/registerDialogBox'
    import logindialogbox from '../components/loginDialogBox'
    import dynamic from '../components/dynamic';

    export default {
        name: 'community',
        components: {
            dynamic,
            logindialogbox,
            rgisterdialogbox,
            bottomNav,
        },
        data() {
            return {
                topList: [],
                topImgList: [],
                recommendList: [],
                sortByComment: 0,
                sortByPublish: 1,
                tabList: [],
                imgList: [],
                sortData: ['最新发布', '最新评论'],
                sortTab: '最新发布',
                active: '',
                showDialog: false,
                showMask: false, //遮罩层
                showeRgister: false,
                tabsBox: false,
                sortList: false,
                gameID: '',
                title: '',
                currIndex: 0,
                loading: false,
                finished: false,
                finishedText: '正在加载中...',
                refreshing: false,
                page: 1,
                AnnouncementList: []
            }
        },
        mounted() {
            console.log('---2022.02.21.16.31')
            this.page = 1
            this.gameID = localStorage.getItem('gameID')
            this.title = localStorage.getItem('gameTitle')
            this.getTokeninfor()
            this.getClassifyList()
            // this.getPostsList()
            this.getAnnouncementList()
        },
        activated() {
            let token = localStorage.getItem('token')
            this.gameID = localStorage.getItem('gameID')
            this.title = localStorage.getItem('gameTitle')
            this.getTokeninfor()
            this.getClassifyList()
        },
        methods: {
            /**加载页面时判断token是否到期*/
            getTokeninfor() {
                this.$post('/api/createPosts')
                    .then((response) => {
                        if (response.code === 10000) {
                            localStorage.setItem('token', '')
                        }
                    })
            },
            /**tab分类 */
            getClassifyList() {
                this.$get(`/api/getClassifyList/${this.gameID}`)
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.tabList = JSON.parse(response.data)
                        } else {
                            this.tabList = []
                        }
                        this.getPostsList();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.getPostsList();
                    })
                    
            },
            /**注册 */
            handleRegister() {
                this.showeRgister = !this.showeRgister
            },
            /**关闭弹窗 */
            handleClose() {
                this.showDialog = false
                this.showeRgister = false
                this.showMask = false
                this.getClassifyList()
                this.getPostsList()
            },
            /**登录 */
            handleLogin() {
                this.showeRgister = !this.showeRgister
                this.showDialog = true
            },
            /**搜索 */
            goSearch() {
                this.$router.push({
                    path: '/search'
                })
            },
            /** 切换标签tab */
            handleClick(index) {
                this.currIndex = index
                this.active = index
                this.tabsBox = false
                this.recommendList = [] //切换标签时要将原数组清空
                this.loading = true // 加载中
                this.finished = false
                this.page = 1
                this.getPostsList()
            },
            /**获取帖子信息 */
            getPostsList() {
                this.$post('/api/getPostList', {
                    gameID: this.gameID,
                    page: this.page,
                    classifyID: this.active,
                    sortByComment: this.sortByComment,
                    sortByPublish: this.sortByPublish
                }).then((response) => {
                    if (response.msg === 'success') {
                        this.topList = response.data.top;
                        // console.log('---置顶--community--top', this.topList)
                        // this.recommendList = [] //获取其他标签时，要把原来的数据清空 
                        // this.recommendList = response.data.list
                        if (this.page === 1) {
                            this.recommendList = response.data.list
                        } else {
                            this.recommendList = this.recommendList.concat(response.data.list)
                        }
                        this.loading = false;
                        this.page++;
                        //如果请求返回的数据为空，就不请求
                        if (response.data.list.length == 0) {
                            this.finished = true;
                            this.finishedText = '--- 没有更多了 ---'
                            return
                        }
                    } else {
                        this.recommendList = [];
                    }
                })
            },
            /**上拉加载 */
            onLoad() {
                this.refreshing = false
                this.getPostsList()
                // console.log('---触发了')
            },
            /**下拉刷新 */
            onRefresh() {
                this.$toast('已加载最新')
                this.finished = false; // 清空列表数据
                this.loading = true; // 重新加载数据, 将 loading 设置为 true，表示处于加载状态
                this.page = 1
                this.refreshing = false
                this.getPostsList()
            },
            /**创建帖子 */
            createPlacard() {
                /** 判断是否登录 */
                let hasToken = localStorage.getItem('token');
                if (hasToken === '' || hasToken == null) {
                    this.showDialog = true
                    this.showMask = true
                } else {
                    this.$router.push({
                        path: '/myplacard'
                    })
                }
            },
            /** 排序 */
            showSort(item, index) {
                this.sortTab = item
                this.sortList = false
                this.recommendList = [] //切换标签时要将原数组清空
                this.loading = true // 加载中
                this.finished = false
                this.page = 1
                if (index === 0) {
                    this.sortByComment = 0
                    this.sortByPublish = 1
                    this.getPostsList()
                }
                if (index === 1) {
                    this.sortByComment = 1
                    this.sortByPublish = 0
                    this.getPostsList()
                }
            },
            // /**点赞 */
            // handleThumbs(item) {
            //     this.$post('/api/createPostsRelation', {
            //             postId: item.id,
            //             type: 2
            //         })
            //         .then(res => {
            //             if (res.msg === 'success') {
            //                 res.data == '-1' ? item.nices_num += 1 :item.nices_num -= 1
            //                 res.data == '-1' ?item.isMyNIce = 1 :item.isMyNIce = 0
            //             }
            //         })
            //         .catch(err => {})
            // },
            /** 跳转详情页*/
            goPostDetail(id) {
                // console.log('---id', id)
                this.$router.push({
                    path: '/postdetail',
                    query: {
                        postsID: id
                    }
                })
            },
            /**获取公告 */
            getAnnouncementList() {
                this.$get('/api/getAnnouncementList', {
                        gameid: this.gameID,
                        page: this.page
                    })
                    .then((response) => {
                        for (let item of response.data.list) {
                            if (item.content_extra != null) {
                                this.AnnouncementList.push({
                                    url: item.content_extra[0],
                                })
                            }
                        }
                        // console.log('----this.AnnouncementList', this.AnnouncementList)
                    })
            },
        }
    }
</script>
<style scoped>
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 2.75rem;
        background-color: #1890ff;
        color: #ffffff;
    }

    .box_left {
        margin-left: .94rem;
        font-size: 1.13rem;
        font-weight: 800;
    }

    .box_right>>>.van-icon {
        margin-right: .81rem;
    }

    .picture_rotation {
        width: 100%;
        height: 10.31rem;
        background-image: linear-gradient(180deg, #1890ff 0%, #ffffff 100%);
    }

    .picture_rotation img {
        /* width: 21.56rem; */
        /* width: 23rem; */
        width: 96%;
        height: 9.06rem;
        object-fit: cover;
        background-size: cover;
        box-shadow: 0rem 0.13rem 0.25rem 0rem rgba(0, 0, 0, 0.5);
        border-radius: 0.63rem;
    }

    /* .picture_rotation>>>.van-swipe__track {
        width: 90% !important;
    }

    .van-swipe-item {
        flex-shrink: 1 !important;
    } */
    .van-swipe-item {
        text-align: center;
    }

    .action_down {
        position: absolute;
        top: 50%;
        right: 0;
        width: 3rem;
        height: 1rem;
        transform: translateY(-50%);
        z-index: 1;
        text-align: center;
        line-height: 1rem;
    }

    .up {
        top: 25%;
    }

    .van_tabs>>>.van-tabs__line {
        background-color: #1890ff;
    }

    .van_tabs>>>.van-tab {
        border-bottom: solid 0.03rem #dddddd;
        color: #999999;
    }

    /* 
    .new_dynamic {
        font-size: 0.63rem;
        color: #494949;
    }

    .new_dynamic>>>.van-dropdown-menu__title::after {
        opacity: 0 !important;
    }

    .new_dynamic>>>.van-dropdown-menu__item {
        position: absolute;
        right: 0.5rem;
        top: 1rem;
    } */
    .new_dynamic {
        position: relative;
        width: 100%;
        height: 2rem;
        background-color: #ffffff;
        font-size: 0.63rem;
        color: #494949;
    }

    .new_dynamic span {
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        padding: .88rem .88rem .5rem;
    }

    .new_dynamic>>>.van-icon {
        margin: .2rem;
    }

    .dynamic_select {
        position: absolute;
        right: .88rem;
        top: 18rem;
        width: 6.25rem;
        height: 3.88rem;
        border: .05rem solid #dddddd;
        background-color: #ffffff;
        border-radius: .3rem;
        z-index: 1;
        text-align: center;
        line-height: 1.88rem;
        font-size: 0.63rem;
    }

    .create_placard {
        position: fixed;
        right: .94rem;
        bottom: 10%;
        width: 3rem;
        height: 3rem;
        background-color: #1890ff;
        border-radius: 50%;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0rem 0rem 0.19rem 0rem rgba(0, 0, 0, 0.5);
    }

    .tabs_box {
        position: absolute;
        top: 0;
        z-index: 11;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        background-color: #ffffff;
        width: 100%;
        height: 5rem;
        padding-left: 1rem;
        box-sizing: border-box;
    }

    .tabs_box a {
        color: #999999;
        font-size: .81rem;
    }

    .tabs_box .activecolor {
        color: #1890ff;
    }

    .tabbox {
        width: 100%;
        background-color: white;
        border-bottom: solid 0.03rem #dddddd;
    }

    .tabbox>>>.van-tabs--line .van-tabs__wrap {
        width: 90% !important;
    }

    .tabbox>>>.van-tab {
        flex: 1 0 auto !important;
    }

    .community>>>.user_content_text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }
</style>