<template>
    <div class="box">
        <!-- 消息 -->
        <van-tabs v-model="active" @change="handleClick">
            <van-tab title="消息"></van-tab>
            <van-tab title="关注"></van-tab>
            <!-- 评论、点赞、系统信息 -->
            <div class="logo_pick" v-if="active===0">
                <ul>
                    <li v-for="(item,index) in logoPick" :key='index' @click="goPickPath(item.path)">
                        <div class="circle">
                            <img :src="item.imgUrl" alt="">
                        </div>
                        <div>{{item.name}}</div>
                    </li>
                </ul>
            </div>
            <!-- 消息内容 -->
            <div v-if="active===0">
                <!-- 消息--消息--公告 -->
                <div class="information" v-for="(item,index) in AnnouncementList" :key="index"
                    @click="handleDetail(item)">
                    <ul>
                        <li class="user_avatar">
                            <div class="user_left">
                                <img src="../assets/logo.png" alt="">
                                <span class="user_name">{{item.account_name}}</span>
                                <span>官方</span>
                            </div>
                            <div class="publish_time">{{getDate(item.utime * 1000)}}</div>
                        </li>
                        <li class="information_activity">
                            <!-- <span>有奖互动</span> | <span>123456789</span> -->
                            <span>{{item.content}}</span>
                        </li>
                        <!-- <li class="information_pic" v-for="(img,ind) in item.content_extra" :key="ind">
                            <img :src="img" alt="">
                        </li> -->
                        <li class="information_pic" v-if="item.content_extra != null">
                            <img :src="item.content_extra[0]" alt="">
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 关注动态 -->
            <div v-if="active===1">
                <!-- 下拉加载 上拉刷新 -->
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list v-model="loading" :finished="finished" :immediate-check='false'
                        :finished-text="finishedText" @load="onLoad">
                        <dynamic class="infor" :tag='true' :List="recommendList" @putItem='goPostDetail($event)'>
                        </dynamic>
                    </van-list>
                </van-pull-refresh>
            </div>
        </van-tabs>
        <bottom-nav></bottom-nav>
    </div>
</template>
<script>
    import moment from 'moment'
    import bottomNav from '../components/bottomNav'
    import dynamic from '../components/dynamic.vue'
    export default {
        name: 'information',
        components: {
            bottomNav,
            dynamic

        },
        data() {
            return {
                active: 0,
                logoPick: [{
                        name: '评论',
                        path: 'comment',
                        imgUrl: require('../assets/icon-comment.png')
                    }, {
                        name: '点赞',
                        path: 'thumbsup',
                        imgUrl: require('../assets/icon-praise.png')
                    },
                    // 暂时关闭
                    //  {
                    //     name: '系统消息',
                    //     path: 'systemmessage',
                    //     imgUrl: require('../assets/icon-message.png')
                    // }
                ],
                gameID: '',
                AnnouncementList: [],
                recommendList: [],
                loading: false,
                finished: false,
                finishedText: '',
                refreshing: false,
                page: 1,
                adPage:1,
            }
        },
        mounted() {
            this.gameID = localStorage.getItem('gameID')
        },
        activated() {
            this.gameID = localStorage.getItem('gameID')
            let token = localStorage.getItem('token')
            this.getAnnouncementList()
            /**未登录时，重定向页面并调起登录弹窗 */
            if (!token) {
                this.$router.replace({
                    path: '/home',
                    query: {
                        login: 'true',
                    }
                })
            }
        },

        methods: {
            /**切换tab页面 */
            handleClick(index) {
                this.active = index
                this.recommendList = [] //切换标签时要将原数组清空
                this.loading = true // 加载中
                this.finished = false
                if (this.active === 1) {
                    this.page = 1
                    this.getFollowPostList()
                }
                else{
                    this.getAnnouncementList()
                }
            },
            goPickPath(item) {
                // 暂时关闭系统消息页面
                if (item === 'systemmessage') {
                    return
                } else {
                    this.$router.push({
                        path: '/' + item
                    })
                }
            },
            /**获取公告 */
            getAnnouncementList() {
                this.$get('/api/getAnnouncementList', {
                        gameid: this.gameID,
                        page: this.adPage
                    })
                    .then((response) => {
                        this.AnnouncementList = response.data.list
                        // console.log('----this.AnnouncementList', this.AnnouncementList)
                    })
            },
            getDate(time) {
                return moment(time).format("YYYY-MM-DD");
            },
            /**关注动态 */
            getFollowPostList() {
                this.$post('/api/getFollowPostList', {
                        page: this.page
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            // this.recommendList = response.data.list
                            // console.log('----this.recommendList',this.recommendList)
                            if (this.page === 1) {
                                this.recommendList = response.data.list
                            } else {
                                this.recommendList = this.recommendList.concat(response.data.list)
                            }
                            this.loading = false;
                            this.page++;
                            //如果请求返回的数据为空，就不请求
                            if (response.data.list.length == 0) {
                                this.finished = true;
                                this.finishedText = '--- 没有更多了 ---'
                                return
                            }

                        } else {
                            this.recommendList = []
                        }
                    })
            },


            /**上拉加载*/
            onLoad() {
                this.refreshing = false
                this.getFollowPostList()
            },
            /**下拉刷新 */
            onRefresh() {
                this.$toast('已加载最新')
                this.finished = false; // 清空列表数据
                this.loading = true; // 重新加载数据, 将 loading 设置为 true，表示处于加载状态
                this.page = 1
                this.refreshing = false
                this.getFollowPostList()
            },

            /** 帖子跳转详情页*/
            goPostDetail(id) {
                this.$router.push({
                    path: '/postdetail',
                    query: {
                        postsID: id
                    }
                })
            },
            /** 公告详情页 */
            handleDetail(item) {
                this.$router.push({
                    path: '/noticedetail',
                    query: {
                        noticeId: item.id
                    }
                })
            }

        }
    }
</script>
<style scoped>
    .box>>>.van-tabs__line {
        background-color: #1890ff;
    }

    .box>>>.van-tab__text--ellipsis {
        font-size: 1.13rem;
    }

    .box>>>.van-tab {
        border-bottom: solid 0.03rem #dddddd;
    }

    .logo_pick {
        width: 100%;
        height: 7.38rem;
        background-color: #f8f8f8;
    }

    .logo_pick ul {
        display: flex;
        justify-content: space-around;
        padding-top: 1.81rem;
    }

    .logo_pick ul li {
        text-align: center;
    }

    .information {
        width: 100%;
        height: auto;
        background-color: #ffffff;
    }

    .information:last-child {
        padding: 0 0 5rem 0;
    }

    .information ul {
        padding: 1.25rem .94rem 0 .94rem;
    }

    .circle {
        width: 3.13rem;
        height: 3.13rem;
        border-radius: 50%;
        /* background-color: #ffa54f; */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circle img {
        width: 3.13rem;
        height: 3.13rem;
    }

    .user_avatar {
        display: flex;
        justify-content: space-between;
        /* padding: 1.25rem .94rem 0 .94rem; */
    }

    .user_left img {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 50%;
        vertical-align: middle;
    }

    .user_left .user_name {
        font-size: 0.81rem;
        color: #1890ff;
        margin-left: .25rem;
    }

    .user_left span:last-child {
        color: #ff4d4f;
        display: inline-block;
        font-size: .63rem;
        width: 1.88rem;
        height: 1rem;
        background-color: #ffffff;
        border-radius: 0.13rem;
        border: solid 0.06rem #ff4d4f;
        text-align: center;
        margin-left: .56rem;
    }

    .publish_time {
        line-height: 2rem;
    }

    .information_activity {
        font-size: 0.81rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }

    .information_pic img {
        width: 100%;
        height: 100%;
        border-radius: .8rem;
    }

    .information_pic {
        margin-top: .59rem;
        /* padding-bottom: 1rem; */
        /* border-bottom: .03rem solid #dddddd; */
    }

    .information li:last-child {
        border-bottom: .03rem solid #dddddd;
        padding-bottom: 1rem;
    }

    .infor>>>.user_content_text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }
</style>