/**
 * 1. 设置请求超时时间和请求头
 * 2. 添加请求拦截器
 * 3. 添加响应拦截器
 */
// import router from '@/router'
import axios from 'axios'
import qs from 'qs' // 引入qs模块，用来序列化post类型的数据


// axios.defaults.baseURL = 'http://192.168.10.17:8091' //默认服务器的地址

const http = axios.create({
    timeout: 1000 * 30, //设置默认的请求超时时间
    withCredentials: false, // 跨域设置
    //Content-Type:在http请求的头中，向服务器指定它应该期望的数据。 如果服务器允许并接受多种类型的内容， 则服务器可以使用此字段知道如何解释请求的主体。
    // headers: {
    //     'Content-Type': 'application/json; charset=utf-8'
    // },
})

/**
 * 请求拦截器
 */
http.interceptors.request.use(config => {
    let hasToken = localStorage.getItem('token')
    if (hasToken || hasToken === '') { //存在token的时候，携带上token
        config.headers['token'] = window.localStorage.getItem('token') //请求头带上token  
    }
    return config
}, error => {
    Message.error({
        message: '请求超时!'
    })
    return Promise.reject(error)
})
/**
 * 响应拦截器
 */
http.interceptors.response.use(response => {
    const status = response.status
    if (status == 200) {
        if (response.data.code === 10000) { //返回的code 10000，token失效
            // localStorage.clear()
            // localStorage.removeItem('token')
        }
        return Promise.resolve(response)
    } else {
        return Promise.reject(response)
    }
}, error => {
    return Promise.reject(error)
})

export default http

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        http.get(url, {
                params: params
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data) {
    return new Promise((resolve, reject) => {
        http.post(url, qs.stringify(data))
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}
/***
 * 封装post图片上传
 * @param url
 * @param data
 * @returns { Promise }
 */
export function postImg(url, data) {
    return new Promise((resolve, reject) => {
        http.post(url, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })

}