import Vue from 'vue'
import Vuex from 'vuex'
import {
    SET_CURRDAMEID
} from './mutation-types'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        // phoneNumber:'',
        currGameId: '',
    },
    mutations: {
        [SET_CURRDAMEID](state, payload) {
            state.currGameId = payload;
        }
    }
})
export default store