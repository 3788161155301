<template>
    <div class="post">
        <!-- 帖子详情 -->
        <header-information title='帖子详情' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <dynamic :isActive='true' :actionPick='false' :List="detailList" :imgList='imgList' :btnshow='true'
            :contentHtml='true' :showGZ='detailData.api_uid == this.userId ? false : true' @putInfo='gopersonal($event)'
            @putGZ='followRalation($event)' @putUrl='viewPic($event)' @putCopy='handleClick()' :screenHeight="'auto'"
            :screenWidth="'100%'" :screenMargin="'0 .94rem'">
        </dynamic>
        <!-- 评论 -->
        <div class="comment">
            <div class="new_dynamic" @click="showSort()">
                <!-- <span>全部回复{{this.commentList.length}}</span> -->
                <span>全部回复{{detailData.comment_num}}</span>
                <!-- <span v-show="hot">
                    <van-icon name="sort" />热门评论
                </span>
                <span v-show="!hot">
                    <van-icon name="sort" />最新评论
                </span> -->
            </div>
            <!-- 评论内容 -->
            <div v-for="(item,index) in commentList" :key="index">
                <div class="comment_list">
                    <div class="comment_info">
                        <img :src="item.avatar_url != '' ? item.avatar_url :''" alt="" @click="gopersonal(item)">
                        <span>{{item.name != '' ? item.name : ''}}</span>
                        <span>
                            <van-tag plain type="primary">{{detailData.api_uid === item.api_uid ? '楼主' : '层主'}}
                            </van-tag>
                        </span>
                        <van-tag type="success" color="#5aead2">lv1</van-tag>
                    </div>
                    <div class="comment_up">
                        <span>{{index+1}}楼</span>
                        <!-- 暂时关闭  -->
                        <!-- <span>
                            <van-icon class="iconfont" class-prefix="icon" name="more" />
                        </span> -->
                    </div>
                </div>
                <!-- <div class="comment_post" @click="show = true"> -->
                <div class="comment_post">
                    {{item.content}}
                </div>
                <div class="comment_time">
                    <div>{{getDate(item.ctime * 1000)}}</div>
                    <div class="pickaction">
                        <!-- 点赞 -->
                        <van-icon v-if="!item.is_nice" class="iconfont" class-prefix="icon" name="dianzan"
                            @click="commentPickAction(item)" />
                        <van-icon v-else class="iconfont yi" class-prefix="icon" name="yidianzan"
                            @click="commentPickAction(item)" />
                        <span style="margin-right: .66rem;">{{item.nices_num}}</span>
                        <!-- 评论 -->
                        <van-icon class="iconfont" class-prefix="icon" name="pinglun" @click="onClickComment(item)" />
                        <span>{{item.comment_num}}</span>
                    </div>


                </div>
                <div class="comment_post comment_content" v-if="item.reply_info != ''">
                    <div v-for="(i,ind) in item.reply_info" :key="ind">
                        <p v-if="item.reply_info != ''" @click="goCommentDetail(i.cid)">
                            <span class="uphost">{{i.reply_name != '' ? i.reply_name : ''}}</span>
                            <span>
                                <!-- 待修改 -->
                                <van-tag plain type="primary" v-if="i.reply_uid === detailData.api_uid">楼主</van-tag>
                                <van-tag plain type="primary"
                                    v-if="i.reply_uid != detailData.api_uid && i.reply_uid === item.api_uid">层主
                                </van-tag>
                                <!-- <van-tag plain type="primary" v-if="i.reply_uid != detailData.api_uid && i.reply_uid != item.api_uid">1</van-tag> -->
                            </span>
                            <span v-if="i.reply_uid === i.to_uid">:</span>
                            <!-- <span v-if="i.reply_uid != item.api_uid || i.to_uid != item.api_uid">回复</span> -->
                            <span v-if="i.reply_uid != i.to_uid ">回复</span>
                            <span v-if="i.reply_uid != item.api_uid || i.to_uid != item.api_uid"
                                class="uphost">{{i.to_name != '' ? i.to_name : ''}}
                            </span>
                            <span v-if="i.reply_uid != i.to_uid ">
                                <!-- 待修改 -->
                                <van-tag plain type="primary" v-if="i.to_uid === detailData.api_uid">楼主</van-tag>
                                <van-tag plain type="primary"
                                    v-if="i.to_uid != detailData.api_uid && i.to_uid == item.api_uid">层主</van-tag>
                                <!-- <van-tag plain type="primary"
                                    v-if="i.to_uid != detailData.api_uid && i.to_uid != item.api_uid"></van-tag> -->
                                <span>:</span>
                            </span>
                            <span>{{i.content}}</span>

                        </p>

                    </div>
                </div>
            </div>
            <div class="release">
                <div>
                    <van-field placeholder="说点什么~" readonly @click="onClick(detailData.name)" />
                </div>
                <div class="release_img">
                    <!-- 评论 -->
                    <van-icon class="iconfont" class-prefix="icon" name="pinglun" @click="onClick(detailData.name)" />
                    <span>{{detailData.comment_num}}</span>
                    <!-- 收藏 -->
                    <van-icon v-if="!detailData.iscollect" class="iconfont" class-prefix="icon" name="shoucang"
                        @click="postPickAction(3)" />
                    <van-icon v-else class="iconfont collect" class-prefix="icon" name="yishoucang"
                        @click="postPickAction(3)" />
                    <span>{{detailData.collection_num}}</span>
                    <!-- 点赞 -->
                    <van-icon v-if="!detailData.isMyNIce" class="iconfont" class-prefix="icon" name="dianzan"
                        @click="postPickAction(2)" />
                    <van-icon v-else class="iconfont yi" class-prefix="icon" name="yidianzan"
                        @click="postPickAction(2)" />
                    <span>{{detailData.nices_num}}</span>
                </div>
            </div>

            <!-- 弹出框 -->
            <van-popup v-model="show" position="top" :style="{ height: '30%' }">
                <div class="po_input">
                    <!-- <van-field v-model="value" type="textarea" :placeholder="'回复' + detailData.name" /> -->
                    <van-field v-model="value" type="textarea" :placeholder="'回复' + CommentedUserName" />
                </div>
                <div class="po_btn">
                    <van-button round type="info" size="mini" v-show="currReply" @click="submit()">发布</van-button>
                    <van-button round type="info" size="mini" v-show="Reply" @click="createPostsCommentReply()">发布
                    </van-button>
                </div>
            </van-popup>

        </div>
        <!-- 查看大图 -->
        <!-- <view-picture :showPic='showPic' @clickCanel='handleCancel()' :picUrl='picUrl'></view-picture> -->
        <!-- 复制路径 -->
        <input id="_copy" v-show="false" v-model="url" />
        <!-- 弹窗遮罩层 -->
        <div class="mask" v-show="showShare" @click="showShare = !showShare"></div>
        <!-- 分享面板弹窗 -->
        <div class="share_box" v-show="showShare">
            <div id="btn" class="circle_copy" :data-clipboard-text="url" data-clipboard-action="copy"
                ddata-clipboard-target="_copy" @click="copy()">
                <span class="yuan">
                    <van-icon class="iconfont" class-prefix="icon" name="copy" />
                </span>
                <span style="font-size: 0.81rem;color: #494949;">复制链接</span>
            </div>
            <!-- 新增需求  编辑帖子  待修改 -->
            <div class="circle_warn" v-if="detailData.api_uid == this.userId" @click="HandleEdit(postsID)">
                <span class="yuan">
                    <van-icon class="iconfont" class-prefix="icon" name="bianji" />
                </span>
                <span style="font-size: 0.81rem;color: #494949;">编辑</span>
            </div>
            <!-- 暂时关闭 -->
            <!-- <div class="circle_warn">
                    <span class="yuan">
                        <van-icon class="iconfont" class-prefix="icon" name="warn" />
                    </span>
                    <span style="font-size: 0.81rem;color: #494949;">举报</span>
                </div> -->
        </div>

        <van-overlay :show="showMask">
            <!-- 登录 -->
            <logindialogbox :showDialog='showDialog' @putRegister='handleRegister' @Close='handleClose'>
            </logindialogbox>
            <!-- 注册 -->
            <rgisterdialogbox :showeRgister='showeRgister' @Login='handleLogin' @Close='handleClose'></rgisterdialogbox>
        </van-overlay>
    </div>
</template>
<script>
    import {
        ImagePreview
    } from 'vant';
    import moment from 'moment'
    import headerInformation from '../components/headerInformation'
    import dynamic from '../components/dynamic'
    import rgisterdialogbox from '../components/registerDialogBox'
    import logindialogbox from '../components/loginDialogBox'
    // import viewPicture from '../components/viewPicture'
    import Clipboard from 'clipboard';
    export default {
        name: 'postDetail',
        components: {
            headerInformation,
            dynamic,
            // viewPicture,
            logindialogbox,
            rgisterdialogbox,
            [ImagePreview.Component.name]: ImagePreview.Component,
        },
        data() {
            return {
                showDialog: false,
                showMask: false, //遮罩层
                showeRgister: false,
                detailList: [],
                imgList: [],
                page: 1,
                createTime: '',
                hot: true,
                value: '',
                postsID: '',
                commentList: [],
                reply_info: [],
                show: false,
                recontent: '',
                detailData: {
                    comment_num: '',
                    nices_num: '',
                    collection_num: '',
                    iscollect: '',
                    isMyNIce: '',
                    name: '',
                    id: '',
                    api_uid: ''

                },
                showGZ: true, //关注按钮
                userId: '',
                CommentedUserName: '', //被评论的用户名字
                currReply: false, //当前回复的对象
                Reply: false,
                to_uid: '',
                cid: '',
                // showPic: false, //是否显示大图
                // picUrl: '',
                imgViewShow: false,
                images: [],
                showShare: false,
                url: ''

            }
        },
        created() {
            this.url = location.href
            this.userId = localStorage.getItem('userId')
            this.postsID = this.$route.query.postsID
            this.getTokeninfor()
        },
        mounted() {
            this.getPostsList()

        },
        methods: {
            /**加载页面时判断token是否到期*/
            getTokeninfor() {
                this.$post('/api/createPostsCommentReply')
                    .then((response) => {
                        if (response.code === 10000) {
                            localStorage.setItem('token', '')
                        }
                    })
            },
            /**注册 */
            handleRegister() {
                this.showeRgister = !this.showeRgister
            },
            /**关闭弹窗 */
            handleClose() {
                this.showDialog = false
                this.showeRgister = false
                this.showMask = false
            },
            /**登录 */
            handleLogin() {
                this.showeRgister = !this.showeRgister
                this.showDialog = true
            },
            /**获取帖子详情信息 */
            getPostsList() {
                this.$post('/api/getPostDetail', {
                    postsID: this.postsID,
                }).then((response) => {
                    this.detailData = response.data
                    this.detailList = [response.data]
                    this.commentList = response.data.commentList
                    // this.comment_num = response.data.comment_num
                    // console.log('--response帖子', this.commentList)
                })
            },
            /** 排序 */
            showSort() {
                this.hot = !this.hot
            },
            /** 评论详情页 */
            goCommentDetail(cid) {
                this.$router.push({
                    path: '/commentdetail',
                    query: {
                        cid: cid
                    }
                })
            },
            onClick(name) {
                /** 判断是否登录 */
                let hasToken = localStorage.getItem('token');
                if (hasToken === '') {
                    this.showDialog = true
                    this.showMask = true
                } else {
                    this.show = true
                    this.currReply = true
                    this.Reply = false
                    this.CommentedUserName = name
                }
            },
            /**评论帖子 */
            submit() {
                this.$post('/api/createPostsComment', {
                        postsId: this.postsID,
                        content: this.value,
                        content_extra: '', //评论额外数据（图片路径）
                        type: 1
                    })
                    .then((response) => {
                        this.getPostsList()
                        this.value = ''
                        this.show = false
                        // console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                this.show = false
            },

            getDate(time) {
                return moment(time).format("YYYY-MM-DD");
            },
            /**个人主页*/
            gopersonal(item) {
                this.$router.push({
                    path: '/personalhomepage',
                    query: {
                        uid: item.api_uid
                    }
                })
            },
            /**关注 取消关注 */
            followRalation(item) {
                // console.log(item.isMyFollow)
                if (item.isMyFollow == 0) {
                    this.$post('/api/createFollowRelation', {
                            uid: item.api_uid,
                        })
                        .then((response) => {
                            if (response.msg === 'success') {
                                item.isMyFollow = 1
                                this.$toast('关注成功')
                                // this.getPostsList()
                                // console.log('response---关注', response)
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                } else {
                    this.$post('/api/cancelFollowRelation', {
                            id: item.api_uid,
                        })
                        .then((response) => {
                            if (response.msg === 'success') {
                                item.isMyFollow = 0
                                this.$toast('取消关注成功')
                                // this.getPostsList()
                                // console.log('response---关注', response)
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            },
            /**对帖子的点赞、收藏 */
            postPickAction(type) {
                this.$post('/api/createPostsRelation', {
                        postId: this.postsID,
                        type: type
                    })
                    .then((response) => {
                        if (response.msg === 'success' && type === 2) {
                            response.data == '-1' ? this.detailData.nices_num += 1 : this.detailData.nices_num -= 1
                            response.data == '-1' ? this.detailData.isMyNIce = 1 : this.detailData.isMyNIce = 0
                        }
                        if (response.msg === 'success' && type === 3) {
                            response.data == '-1' ? this.detailData.collection_num += 1 : this.detailData
                                .collection_num -= 1
                            response.data == '-1' ? this.detailData.iscollect = 1 : this.detailData.iscollect = 0
                        }
                        /**登录过期的时候调起登录弹窗*/
                        if (response.code === 10000) {
                            this.showDialog = true
                            this.showMask = true
                        }

                    })
                    .catch((error) => {
                        console.log(error)
                    })
                // console.log(type, '---', this.detailData.nices_num)
            },
            /**对评论的点赞 */
            commentPickAction(item) {
                this.$post('/api/createNiceComment', {
                        cId: item.id,
                        type: 1
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            response.data == '-1' ? item.nices_num += 1 : item.nices_num -= 1
                            response.data == '-1' ? item.is_nice = 1 : item.is_nice = 0
                        }
                        /**登录过期的时候调起登录弹窗*/
                        if (response.code === 10000) {
                            this.showDialog = true
                            this.showMask = true
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            onClickComment(item) {
                /** 判断是否登录 */
                let hasToken = localStorage.getItem('token');
                if (hasToken === '') {
                    this.showDialog = true
                    this.showMask = true
                } else {
                    this.show = true
                    this.Reply = true
                    this.currReply = false
                    this.CommentedUserName = item.name
                    this.cid = item.id
                    this.to_uid = item.api_uid
                }
            },
            /**回复评论 */
            createPostsCommentReply() {
                this.$post('/api/createPostsCommentReply', {
                        cid: this.cid,
                        to_uid: this.to_uid,
                        content: this.value,
                        type: 1
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.getPostsList()
                            this.value = ''
                            this.show = false
                            // console.log(response)
                        }

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**查看大图 */
            // viewPic(url) {
            //     // console.log('---url', url)
            //     this.picUrl = url
            //     this.showPic = true
            // },
            /**查看大图*/
            viewPic(url) {
                ImagePreview({
                    images: [url],
                    closeable: true,
                    showIndex: false
                });
            },
            // handleCancel() {
            //     this.showPic = false
            // },

            handleClick() {
                this.showShare = true
            },
            /** 复制链接*/
            copy() {
                let that = this;
                let clipboard = new Clipboard("#btn");
                clipboard.on('success', e => {
                    that.$toast("复制成功！")
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    that.$toast("该浏览器不支持自动复制")
                    clipboard.destroy()
                })
                this.showShare = false
            },
            /**编辑 */
            HandleEdit(postsID) {
                /** 判断是否登录 */
                let hasToken = localStorage.getItem('token');
                if (hasToken === '') {
                    this.showDialog = true
                    this.showMask = true
                    this.showShare = false
                    this.$toast("登录过期，请重新登录！")
                } else {
                    this.$router.push({
                        path: '/myplacard',
                        query: {
                            postsID: postsID
                        }
                    })
                }
            }
        },
    }
</script>
<style scoped>
    .post {
        width: 100%;
        height: 2.75rem;
    }

    .comment {
        margin-top: .5rem;
        width: 100%;
        height: auto;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 1rem 1rem 5rem;
    }

    .new_dynamic {
        position: relative;
        width: 100%;
        height: 2rem;
        background-color: #ffffff;
        font-size: 0.63rem;
        color: #494949;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .new_dynamic span:first-child {
        font-size: 0.81rem;
        color: #999999;
    }

    .comment_list {
        display: flex;
        justify-content: space-between;
        margin-top: .97rem;
    }

    .new_dynamic>>>.van-icon {
        margin: .2rem;
    }

    .dynamic_select {
        position: absolute;
        right: .88rem;
        top: 18rem;
        width: 6.25rem;
        height: 5.88rem;
        border: .05rem solid #dddddd;
        background-color: #ffffff;
        border-radius: .3rem;
        z-index: 1;
        text-align: center;
        line-height: 1.88rem;
        font-size: 0.63rem;
    }

    .comment_list img {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 50%;
    }

    .comment_info,
    .comment_time div:last-child {
        display: flex;
        align-items: center;

    }

    .comment_info span {
        margin-right: .25rem;
    }

    .comment_up span {
        margin-left: 1.25rem;
        font-size: 0.63rem;
        color: #999999;
    }

    .comment_post {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 1rem 2rem;
        font-size: 0.81rem;
        white-space: pre-wrap;
    }

    .comment_time {
        display: flex;
        justify-content: space-between;
        margin-left: 2rem;
    }

    .comment_time div:first-child {
        font-size: 0.81rem;
        color: #999999;
    }

    .comment_content {
        background-color: #f8f8f8;
        border-radius: 0.31rem;
        margin-top: 1rem;
    }

    .uphost {
        color: #1890ff;
        font-size: 0.81rem;
    }

    .release {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 2.75rem;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
    }

    .release img {
        width: 1.38rem;
        height: 1.38rem;
        margin-left: .5rem;
    }

    .release span {
        color: #999999;
        margin-right: .3rem;
    }

    .release_img {
        width: 35%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 1rem;
    }

    .po_input>>>.van-field--min-height .van-field__control {
        min-height: 9rem !important;
    }

    .po_btn {
        position: absolute;
        bottom: .5rem;
        right: .5rem;
    }

    .po_btn>>>.van-button--mini {
        padding: 0 1.5rem !important;
    }

    .pickaction img,
    .release_img img {
        width: 1.25rem;
        height: 1.06rem;
    }

    /* 分享面板 */

    .mask {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(17, 16, 16, 0.8);
        z-index: 1;
    }

    .share_box {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 1rem;
        border-radius: .63rem;
        width: 88%;
        height: 8rem;
        margin: 0 auto;
        background-color: #ffffff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        z-index: 2;
    }

    .icon-copy:before {
        font-size: 1.8rem;
        color: black;
    }

    .icon-warn:before {
        font-size: 2rem;
        color: black;
    }

    .circle_copy,
    .circle_warn {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .yuan {
        display: block;
        width: 2.7rem;
        height: 2.7rem;
        border: .09rem solid black;
        border-radius: 50%;
        text-align: center;
        line-height: 2.7rem;
        margin: 0 0 .5rem 0;
    }

    .yi {
        color: #1890ff;
    }

    .collect {
        color: #ff8600;
    }
</style>