<template>
    <div class="register_dialog" v-if="showeRgister">
        <!-- 注册页面 -->
        <p class="register_title">注册奇玩平台账号</p>
        <van-form>
            <van-field v-model="phoneNumber" name="phoneNumber" placeholder="请输入手机号">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="shouji"></van-icon>
            </van-field>
            <van-field v-model="phoneCode" center clearable placeholder="手机验证码">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="safe"></van-icon>
                <template #button>
                    <van-button native-type="button" size="small" type="info" @click="handleCode(phoneNumber)"
                        :class="oldcount > 0 ? 'count' : ''">{{oldcount > 0 ? oldcount + 's' : '发送验证码'}}</van-button>
                </template>
            </van-field>
            <van-field v-model="password" type="password" name="pattern" placeholder="密码由6～20字母或数字组成">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="mima"></van-icon>
            </van-field>
            <van-field v-model="passwordRepetition" type="password" name="密码" placeholder="请确认密码">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="mima"></van-icon>
            </van-field>
            <van-field v-model="IDCARDName" name="IDCARDName" placeholder="请输入真实姓名">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="bianji"></van-icon>
            </van-field>
            <van-field v-model="nickName" name="nickName" placeholder="请输入昵称">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="bianji"></van-icon>
            </van-field>
            <van-field v-model="IDCARDNumber" name="IDCARDNumber" placeholder="请输入有效的18位身份证号码">
                <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="Id"></van-icon>
            </van-field>
            <van-field name="checkbox">
                <template #input>
                    <van-checkbox v-model="agreecheck">阅读并同意奇玩网络用户协议</van-checkbox>
                </template>
            </van-field>
            <div style="margin: 0 1rem .5rem;">
                <van-button block type="info" native-type="submit" @click="Regist()">立即注册</van-button>
            </div>
            <div style="text-align: center;">已有账号!<span style="color:#ff8600;margin-left:.5rem;"
                    @click="handleClick()">立即登录</span></div>
        </van-form>
        <span class="close" @click="handleClose()">X</span>
    </div>
</template>
<script>
    export default {
        name: 'rgisterDialogBox',
        props: {
            showeRgister: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                phoneNumber: '',
                IDCARDName: '',
                password: '',
                passwordRepetition: '',
                phoneCode: '',
                IDCARDNumber: '',
                nickName: '',
                agreecheck: true,
                oldcount: '',
                oldtimer: null,
            }
        },
        methods: {
            /**登录 */
            handleClick() {
                this.$emit('Login')
            },
            /**关闭 */
            handleClose() {
                this.$emit('Close')
            },
            /** 注册 */
            Regist() {
                if (!/^1[0-9]{10}$/.test(this.phoneNumber)) {
                    this.$toast(this.phoneNumber === '' ? '手机号不能为空' : '手机号格式不正确')
                    return
                }
                if (this.phoneCode === '') {
                    this.$toast('验证码不能为空')
                    return
                }
                if (!/^[0-9A-Za-z]{6,20}$/.test(this.password)) {
                    this.$toast(this.password === '' ? '密码不能为空' : '密码格式不正确')
                    return
                }
                if (this.passwordRepetition === '') {
                    this.$toast('重复密码不能为空')
                    return
                }else if (this.passwordRepetition != this.password) {
                    this.$toast('两次密码输入不一致')
                    return
                }
                if (this.IDCARDName === '') {
                    this.$toast('真实姓名不能为空')
                    return
                }
                if (this.nickName === '') {
                    this.$toast('昵称不能为空')
                    return
                }
                if(!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.IDCARDNumber)){
                     this.$toast(this.IDCARDNumber === '' ? '身份证号不能为空' : '身份证号格式不正确')
                     return
                }
                this.$post('/api/register', {
                        phoneNumber: this.phoneNumber,
                        phoneCode: this.phoneCode,
                        password: this.password,
                        passwordRepetition: this.passwordRepetition,
                        IDCARDName: this.IDCARDName,
                        IDCARDNumber: this.IDCARDNumber,
                        nickName: this.nickName
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.$emit('Login')
                        } else {
                            this.$toast(response.msg)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            },
            /**获取验证码 */
            handleCode(phoneNumber) {
                if (phoneNumber) {
                    const TIME_COUNT = 60;
                    this.$get(`/api/sendSms/${phoneNumber}`).then((response) => {
                        if (response.msg === 'success') {
                            if (!this.oldtimer) {
                                this.oldcount = TIME_COUNT;
                                this.oldtimer = setInterval(() => {
                                    if (this.oldcount > 0 && this.oldcount <= TIME_COUNT) {
                                        this.oldcount--;
                                    } else {
                                        clearInterval(this.oldtimer);
                                        this.oldtimer = null;
                                    }
                                }, 1000)
                            }
                        }
                    })
                } else {
                    this.$toast('请输入手机号');
                }
            }
        }
    }
</script>
<style scoped>
    .register_dialog {
        position: fixed;
        left: 0;
        right: 0;
        top: 9%;
        margin: 0 auto;
        width: 19.44rem;
        height: 31.69rem;
        background-color: #ffffff;
        border-radius: 0.63rem;
    }

    .register_title {
        text-align: center;
        font-size: 1rem;
    }

    .close {
        position: absolute;
        top: .45rem;
        right: .85rem;
        cursor: pointer;
        color: rgb(167, 167, 167);
    }

    .register_dialog>>>.van-tabs--line .van-tabs__wrap {
        border-top-left-radius: .63rem;
        border-top-right-radius: .63rem;
    }

    .count {
        width: 5rem;
        background-color: #c0bcbc;
        border: .5rem solid #c0bcbc;
    }
</style>