<template>
    <div class="thumbsup">
        <!-- 粉丝 -->
        <header-information title='粉丝' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <!-- 粉丝列表 -->
        <mygz-list :list='myFansList' :btnshow="false"></mygz-list>
    </div>
</template>
<script>
    import headerInformation from '../components/headerInformation'
    import mygzList from '../components/gzList.vue'
    export default {
        name: 'myFans',
        components: {
            headerInformation,
            mygzList
        },
        data() {
            return {
                myFansList:[],
            }
        },
        mounted(){
            this.getmyFansList();
        },
        methods:{
             /**粉丝列表 */
            getmyFansList() {
                this.$get('/api/myFansList')
                    .then((response) => {
                        this.myFansList = response.data
                        // console.log("response.data粉丝---", this.myFansList)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
        }
    }
</script>
<style scoped>
    .thumbsup {
        width: 100%;
        height: 2.75rem;
    }
</style>