<template>
    <div class="box">
        <!-- 我的收藏 -->
        <header-information title='我的收藏' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <!-- 下拉加载 上拉刷新 -->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" :immediate-check='false' :finished-text="finishedText"
                @load="onLoad">
                <dynamic class="mycollection" :tag='true' :List="collectList" :imgList='imgList'></dynamic>
            </van-list>
        </van-pull-refresh>
    </div>
</template>
<script>
    import moment from 'moment'
    import headerInformation from '../components/headerInformation'
    import dynamic from '../components/dynamic'
    export default {
        name: 'myCollection',
        components: {
            headerInformation,
            dynamic
        },
        data() {
            return {
                collectList: [],
                imgList: [],
                page: 1,
                loading: false,
                finished: false,
                finishedText: '',
                refreshing: false,

            }
        },
        mounted() {
            this.getPostListCollect()
        },
        methods: {
            /**收藏列表 */
            getPostListCollect() {
                this.$post('/api/getPostListCollect', {
                        page: this.page
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            // this.collectList = response.data.list
                            if (this.page === 1) {
                                this.collectList = response.data.list
                            } else {
                                this.collectList = this.collectList.concat(response.data.list)
                            }
                            this.loading = false;
                            this.page++;
                            //如果请求返回的数据为空，就不请求
                            if (response.data.list.length == 0) {
                                this.finished = true;
                                this.finishedText = '--- 没有更多了 ---'
                                return
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**上拉加载*/
            onLoad() {
                this.getPostListCollect()
            },
            /**下拉刷新 */
            onRefresh() {
                this.$toast('已加载最新')
                this.page = 1
                this.getPostListCollect()
                this.refreshing = false
            },
        }
    }
</script>
<style scoped>

</style>