<template>
    <div class="box">
        <!-- 首页 -->
        <!-- <header-information bgcolor='#1890ff' :title="title">
            <template v-slot:right>
                <van-icon name="point-gift-o" size='1.25rem' />
            </template>
        </header-information> -->
        <!-- 头部信息 -->
        <div class="box_content">
            <div class="box_left" @click="handleClick()">
                <van-icon name="arrow-left" size='1rem' />返回
            </div>
            <div class="box_center">{{title}}</div>
            <div class="box_right">
                <!-- 暂时关闭 -->
                <!-- <van-icon name="point-gift-o" size='1.25rem' /> -->
            </div>
        </div>

        <div class="container">
            <div class="contain_left" v-if="gameList.length != 0">
                <img :src="gameList[0].avatar_url != '' ? gameList[0].avatar_url :''" alt="">
                <ul>
                    <li><span>{{gameList[0].fans_num}}</span><span>关注</span></li>
                    <li><span>{{gameList[0].message_num}}</span><span>帖子</span></li>
                </ul>
            </div>
            <div class="contain_left">
                <van-button round color='#1890ff' size="small" icon='down'
                    @click="goDownLoad(gameList[0].download_url)">下载</van-button>
                <!-- <van-button round color='#1890ff' size="small" icon='calendar-o'>签到并加入</van-button> -->
            </div>
        </div>
        <!-- 暂不需要 -->
        <!-- <div class="notice">
            <van-swipe class="my-swipe" indicator-color="#767676">
                <van-swipe-item v-for="(item,index) in 2" :key="index">
                    <ul>
                        <li> <img src="../assets/1.png" alt="">
                            <div class="notice_text">公告内容</div>
                        </li>
                        <li> <img src="../assets/1.png" alt="">
                            <div class="notice_text">公告内容</div>
                        </li>
                        <li> <img src="../assets/1.png" alt="">
                            <div class="notice_text">公告内容</div>
                        </li>
                        <li> <img src="../assets/1.png" alt="">
                            <div class="notice_text">公告内容</div>
                        </li>
                    </ul>
                </van-swipe-item>
            </van-swipe>
        </div> -->
        <!--  公告---游戏资讯 -->
        <div class="information">
            <div class="information_title">
                <p class="information_text">游戏资讯</p>
                <p class="more" @click="handleMore()">更多
                    <van-icon name="arrow" />
                </p>
            </div>
            <div class="information_content">
                <div class="content_text" v-for="(notice,index) in AnnouncementList" :key="index"
                    @click="noticeDetail(notice)">
                    <van-tag type="primary" color="rgba(255, 150, 11, 0.9)" style="margin-right: .5rem;">官方</van-tag>
                    <span class="infor_text">{{notice.title}}</span>
                    <span style="float:right;">{{getDate(notice.utime * 1000)}}</span>
                </div>
            </div>
        </div>
        <!-- 推荐 -->
        <!-- 下拉加载 上拉刷新 -->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" :immediate-check='false' :finished-text="finishedText"
                @load="onLoad">
                <!-- 置顶的帖子 -->
                <dynamic class="home" :handLine='true' :List="topList" :imgList='topImgList'
                    @putItem='goPostDetail($event)'>
                </dynamic>
                <dynamic class="home" :List="recommendList" :imgList='imgList' @putItem='goPostDetail($event)'>
                </dynamic>
            </van-list>
        </van-pull-refresh>
        <!-- 底部导航 -->
        <bottom-nav></bottom-nav>
        <!-- 创建帖子 -->
        <div class="create_placard" @click="createPlacard()">
            <van-icon name="plus" size="2rem" />
        </div>
        <van-overlay :show="showMask">
            <!-- 登录 -->
            <logindialogbox :showDialog='showDialog' @putRegister='handleRegister' @Close='handleClose'>
            </logindialogbox>
            <!-- 注册 -->
            <rgisterdialogbox :showeRgister='showeRgister' @Login='handleLogin' @Close='handleClose'></rgisterdialogbox>
        </van-overlay>
    </div>
</template>
<script>
    import moment from 'moment'
    import headerInformation from '../components/headerInformation'
    import bottomNav from '../components/bottomNav'
    import dynamic from '../components/dynamic'
    import rgisterdialogbox from '../components/registerDialogBox'
    import logindialogbox from '../components/loginDialogBox'
    export default {
        name: 'home',
        components: {
            headerInformation,
            dynamic,
            bottomNav,
            logindialogbox,
            rgisterdialogbox,
        },
        data() {
            return {
                showDialog: false,
                showMask: false, //遮罩层
                showeRgister: false,
                topList: [],
                topImgList: [],
                recommendList: [],
                imgList: [],
                loading: false,
                finished: false,
                finishedText: '',
                refreshing: false,
                page: 1,
                gameID: '',
                title: '',
                gameList: [{
                    avatar_url: '',
                    fans_num: '',
                    message_num: '',
                    download_url: ''
                }],
                AnnouncementList: []
            }
        },
        mounted() {
            let token = localStorage.getItem('token')
            this.gameID = localStorage.getItem('gameID')
            this.title = localStorage.getItem('gameTitle')
            this.getTokeninfor()
            this.getPostsList()
            this.getGameList()
            this.getAnnouncementList()
            /** 未登录时，调起登录弹窗*/
            if (this.$route.query.login && !token) {
                this.$router.push({
                    path:'/home'
                })
                this.showDialog = true
                this.showMask = true
            }

        },
        methods: {
            /**加载页面时判断token是否到期*/
            getTokeninfor() {
                this.$post('/api/createPosts')
                    .then((response) => {
                        if (response.code === 10000) {
                            localStorage.setItem('token', '')
                        }
                    })
            },
            /**注册 */
            handleRegister() {
                this.showeRgister = !this.showeRgister
            },
            /**关闭弹窗 */
            handleClose() {
                this.showDialog = false
                this.showeRgister = false
                this.showMask = false
                this.getPostsList()
            },
            /**登录 */
            handleLogin() {
                this.showeRgister = !this.showeRgister
                this.showDialog = true
            },
            /**创建帖子 登录 */
            createPlacard() {
                /** 判断是否登录 */
                let hasToken = window.localStorage.getItem('token');
                if (hasToken === '' || hasToken == null) {
                    this.showDialog = true
                    this.showMask = true
                } else {
                    this.$router.push({
                        path: '/myplacard'
                    })
                }
                // console.log('111',hasToken)
            },
            /**获取游戏列表卡片 */
            getGameList() {
                this.$get('/api/gameMoreList')
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.gameList = response.data.filter(item => item.id == this.gameID)
                            // console.log("---:", this.gameList);
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**获取帖子信息 */
            getPostsList() {
                // var that = this;
                this.$post('/api/getPostList', {
                        sortByPublish: 1,
                        gameID: this.gameID,
                        page: this.page,
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            this.topList = response.data.top;
                            // console.log('---置顶--top', this.topList)
                            //如果请求返回的数据为空，就不请求
                            if (response.data.list.length == 0) {
                                this.finished = true;
                                this.finishedText = '--- 没有更多了 ---'
                                return
                            }
                            if (response.data.pageCount === 1) {
                                this.recommendList = response.data.list
                            } else {
                                this.recommendList = this.recommendList.concat(response.data.list)
                            }
                            this.loading = false;
                            this.page++;

                        }
                    })
            },
            /**上拉加载*/
            onLoad() {
                this.refreshing = false
                this.getPostsList()
            },
            /**下拉刷新 */
            onRefresh() {
                this.$toast('已加载最新')
                this.finished = false; // 清空列表数据
                this.loading = true; // 重新加载数据, 将 loading 设置为 true，表示处于加载状态
                this.refreshing = false
                this.getPostsList()
            },

            /** 跳转详情页*/
            goPostDetail(id) {
                // console.log('---id', id)
                this.$router.push({
                    path: '/postdetail',
                    query: {
                        postsID: id
                    }
                })
            },
            goDownLoad(url) {
                // console.log('---',url)
                window.location.href = url
            },
            handleClick() {
                this.$router.push({
                    path: '/'
                })
            },
            /**获取公告 */
            getAnnouncementList() {
                this.$get('/api/getAnnouncementList', {
                        gameid: this.gameID,
                        page: this.page
                    })
                    .then((response) => {
                        this.AnnouncementList = response.data.list.splice(-2)
                    })
            },
            getDate(time) {
                return moment(time).format('MM-DD');
            },
            /**公告详情页 */
            noticeDetail(notice) {
                // console.log('---公告详情页',notice)
                this.$router.push({
                    path: '/noticedetail',
                    query: {
                        // notice: JSON.stringify(notice)
                        noticeId: notice.id
                    }
                })
            },
            /**查看更多公告（游戏资讯） */
            handleMore() {
                this.$router.push({
                    path: '/allnotice',
                })
            }

        }
    }
</script>
<style scoped>
    .box_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 2.75rem;
        background-color: #1890ff;
        color: #ffffff;
    }

    .box_left {
        margin-left: .94rem;
    }

    .box_left .van-icon {
        top: .2rem;
    }

    .box_center {
        font-size: 1.13rem;
    }

    .box_right {
        margin-right: 2rem;
    }

    .container {
        height: 8.13rem;
        background-color: #1890ff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 .94rem;
    }

    .contain_left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

    }

    .container img {
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 0.75rem;
        border: solid 0.19rem #ffffff;
    }

    ul {
        color: rgba(255, 255, 255, 0.5);
        padding-left: .63rem;
    }

    ul li span:first-child {
        display: inline-block;
        margin-right: .41rem;
        font-size: .88rem;
    }

    ul li span:last-child {
        font-size: .75rem;
    }

    .notice {
        position: relative;
        width: 100%;
        height: 7.5rem;
        background-color: #ffffff;
        border-top-left-radius: .8rem;
        border-top-right-radius: .8rem;
        margin-top: -1.125rem;
    }

    .notice::after {
        content: "";
        border: 0.58rem transparent solid;
        border-bottom-color: #ffffff;
        position: absolute;
        left: 2.2rem;
        top: -1rem;
    }

    .notice_text {
        color: #494949;
        font-size: .81rem;
    }

    .my-swipe ul {
        display: flex;
        /* justify-content: space-evenly; */
        justify-content: flex-start;
        padding: 0;
        overflow: hidden;
    }

    .my-swipe .van-swipe-item {
        height: 7.5rem;
        font-size: .81rem;
        color: #494949;
    }

    .my-swipe>>>.van-swipe__indicator {
        background-color: #767676;
    }

    .my-swipe li {
        width: 3.75rem;
        height: 5.03rem;
        margin: 0.73rem 0 0 .73rem;
    }

    .information {
        width: 100%;
        height: 9rem;
        background-color: #ffffff;
        /* margin: .5rem auto; */
    }

    .information_title {
        display: flex;
        justify-content: space-between;
    }

    .information_text {
        text-indent: .38rem;
        margin-left: .94rem;
        font-weight: bold;
        font-size: 1.06rem;
        border-left: .25rem solid #1890ff;
    }

    .more {
        display: flex;
        align-items: center;
        color: #999999;
        margin-right: .94rem;
    }

    .information_content {
        border-top: .03rem solid #dddddd;
        margin: 0 .94rem;
        padding: .7rem 0;
    }

    .content_text {
        margin: .59rem;
    }

    .van-button {
        border: .05rem solid #ffffff !important;
        margin-left: .5rem;
    }

    .create_placard {
        position: fixed;
        right: .94rem;
        bottom: 10%;
        width: 3rem;
        height: 3rem;
        background-color: #1890ff;
        border-radius: 50%;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0rem 0rem 0.19rem 0rem rgba(0, 0, 0, 0.5);
    }

    .home>>>.user_content_text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }

    .infor_text {
        display: inline-block;
        max-width: 10rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
</style>