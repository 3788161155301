<template>
    <!-- 评论话题列表组件 -->
    <div class="recommend">
        <div class="recommend_content" v-for="(item,index) in commentList" :key="index" @click="onclick(item.postsID)">
            <!-- <div class="user_avatar">
                <img :src="item.avatarUrl != '' ? item.avatarUrl : require('../assets/logo.png')" alt="">
                <ul>
                    <li>{{item.name != '' ? item.name : 'William Diwedi'}}</li>
                    <li>{{item.times}}</li>
                </ul>
            </div> -->
            <div class="user_content">
                <div class="user_content_title">
                    <span class="user_content_infor">{{item.content != '' ? item.content : ''}}</span>
                </div>
                <ul>
                    <li v-for="(imgUrl,index) in item.extra" :key="index">
                        <img :src="imgUrl === '' ? '' : imgUrl" alt="">
                    </li>
                </ul>
                <div class="user_content_text">
                    原话题： {{item.postsTile != '' ? item.postsTile : ''}}
                </div>
            </div>
            <div class="action_pick">
                <span class="action_text">{{getDate(item.time * 1000)}} {{item.gameInfo != null ? item.gameInfo.title : ''}}</span>
                <van-icon class="iconfont" v-show="deleteBtn" class-prefix="icon" name="shanchu" @click.stop="handleClick(item)" />
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
    export default {
        name: 'commentTopicList',
        props: {
            commentList: {
                type: Array,
                default () {
                    return []
                }
            },
            deleteBtn: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {

            }
        },
        methods:{
             getDate(item){
                return moment(item).format('MM-DD');
            },
            handleClick(item){
                this.$emit('putItem',item)
            },
            onclick(id){
                this.$emit('goDetail',id)
            }
        }
    }
</script>
<style scoped>
    .recommend {
        width: 100%;
        height: auto;
        background-color: #ffffff;
    }

    .recommend_content {
        width: 100%;
        height: auto;
        border-bottom: .03rem solid #dddddd;
        margin: 0 auto;
        padding: 1rem 0;
    }

    .user_avatar {
        display: flex;
        justify-content: flex-start;
        margin-left: .94rem;
    }

    .user_avatar img {
        width: 1.75rem;
        height: 1.75rem;
        border: solid 0.06rem #ffffff;
        border-radius: 50%;
    }

    .recommend_content ul {
        color: black;
        font-size: 0.63rem;
    }

    .recommend_content ul li:last-child {
        color: #999999;
    }

    .user_content {
        margin: .81rem auto;

    }

    .user_content_title {
        display: flex;
        align-items: center;
        margin-left: .94rem;
    }

    .user_content_infor {
        display: inline-block;
        width: 17.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 600;
        margin-left: .3rem;
    }

    .user_content_text {
        font-size: .81rem;
        color: #494949;
        margin-left: .94rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .user_content img {
        width: 6.75rem;
        height: 6.75rem;
        border-radius: 0.31rem;
    }

    .user_content ul {
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
    }

    .user_content ul li {
        margin-left: .94rem;
    }

    .action_pick {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 .94rem;
    }

    .action_text {
        font-size: 0.63rem;
        color: #999999;
    }

    .recommend_content:last-child {
        padding-bottom: 3.5rem;
    }
</style>