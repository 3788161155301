<template>
    <div class="box">
        <div class="head">
            社区
        </div>
        <ul class="game_card">
            <li v-for="(item,index) in gameList" :key="index" @click="hancleClick(item)">
                <div class="game_info" :style="{backgroundImage:'url( '+ (item.avatar_url != '' ? item.avatar_url  : '') +')' ,
                     backgroundRepeat: 'no-repeat' , backgroundPosition: 'center' , backgroundSize: 'cover'}">
                    <img :src="item.avatar_url != '' ? item.avatar_url : ''" alt="">
                    <div class="game_data">
                        <div>{{item.title}}</div>
                        <div class="gz"><span>{{item.fans_num}}</span><span>关注</span></div>
                        <div class="tz"><span>{{item.message_num}}</span><span>帖子</span></div>
                    </div>
                </div>
                <!-- <div class="marke"></div> -->
                <div class="marke" :class="`marke${index}`"></div>
                <div class="game_title">{{item.des}}</div>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'index',
        data() {
            return {
                gameList: [],
                hasToken: '',
            }
        },
        mounted() {
            this.hasToken = localStorage.getItem('token')
            this.getGameList()
        },
        methods: {
            /**获取游戏列表卡片 */
            getGameList() {
                this.$get('/api/gameMoreList')
                    .then((response) => {
                        this.gameList = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            hancleClick(item) {
                // this.$store.commit('SET_CURRDAMEID',{id});
                localStorage.setItem('gameID', item.id)
                localStorage.setItem('gameTitle', item.title)
                localStorage.setItem('token', this.hasToken ? this.hasToken : '')
                this.$router.push({
                    path: '/home',
                    query: {
                        gameID: item.id,
                        title:item.title
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .head {
        width: 100%;
        height: 2.75rem;
        background-color: #1890ff;
        text-align: center;
        color: #ffffff;
        font-size: 1.13rem;
        line-height: 2.75rem;
    }

    .game_card li {
        position: relative;
        width: 90%;
        height: 9.06rem;
        box-shadow: 0rem 0.06rem 0.75rem 0rem rgba(0, 0, 0, 0.5);
        border-radius: 0.63rem;
        opacity: 0.84;
        margin: 1.56rem auto;

    }

    .game_card .game_info {

        width: 100%;
        height: 5.8rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-left: .94rem;

        /* background-image:
            url('../assets/playgame.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover; */
    }

    .marke {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 5.8rem;
        margin: 0 auto;
        opacity: .9;
    }

    .marke0,
    .marke3,
    .marke6 {
        background-image: linear-gradient(90deg, rgb(24, 144, 255) 0%, rgb(147, 203, 255) 83%, rgb(175, 213, 255) 100%);
    }

    .marke1,
    .marke4,
    .marke7 {
        background-image: linear-gradient(90deg, #B21108 0%, #c22e26 83%, #FF744C 100%);
    }

    .marke2,
    .marke5,
    .marke8 {
        background-image: linear-gradient(90deg, #089DB2 0%, #70cad6 83%, #CCFFFA 100%);
    }

    .game_info img,
    .game_data div {
        position: relative;
        z-index: 10;
    }

    .game_card .game_data {
        margin-left: .5rem;
    }

    .game_data div {
        margin-top: .2rem;
    }

    .gz {
        display: inline-block
    }

    .tz {
        display: inline-block;
        margin-left: 1rem;
    }

    .game_data div:first-child {
        font-size: 1rem;
        color: #ffffff;
    }

    .game_data div span:first-child {
        font-size: 0.88rem;
        color: #ffffff;
    }

    .game_data div span:last-child {
        font-size: 0.75rem;
        color: #ffffff;
        margin-left: .41rem;
    }

    .game_card .game_title {
        margin-left: .94rem;
        line-height: 3.2rem;
        font-size: 0.81rem;
        color: #494949;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .game_card li img {
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 0.75rem;
        border: solid 0.19rem #ffffff;
    }
</style>