<template>
    <div class="thumbsup">
        <!-- 关注 -->
        <header-information title='关注' bgcolor='#ffffff' wordcolor='#494949'></header-information>
        <!-- 关注列表 -->
        <mygz-list :list='mygzList' @Cancel='cancelFollowRelation($event)' @putItem='enterPersonal($event)'></mygz-list>
    </div>
</template>
<script>
    import headerInformation from '../components/headerInformation'
    import mygzList from '../components/gzList.vue'
    export default {
        name: 'myGZ',
        components: {
            headerInformation,
            mygzList
        },
        data() {
            return {
                mygzList: [],
            }
        },
        mounted() {
            this.getmyGZList();
        },
        methods: {
            /**关注列表 */
            getmyGZList() {
                this.$get('/api/myGZList')
                    .then((response) => {
                        this.mygzList = response.data
                        // console.log("response.data关注---", this.mygzList)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**取消关注人 */
            cancelFollowRelation(item) {
                // console.log('---item',item)
                this.$post('/api/cancelFollowRelation', {
                        id: item.uid
                    })
                    .then((response) => {
                        this.$toast(response.data)
                        this.mygzList = this.mygzList.splice(item.id, 1)
                        // console.log('---',this.mygzList)
                        // this.getmyGZList()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            /**跳转关注人的主页 */
            enterPersonal(id) {
                // console.log('----id', id)
                this.$router.push({
                    path: '/personalhomepage',
                    query: {
                        uid: id
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .thumbsup {
        width: 100%;
        height: 2.75rem;
    }
</style>