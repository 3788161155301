<template>
    <!-- 动态组件 -->
    <div class="recommend">
        <div class="information_title" v-show="handLine">
            <p class="information_text">推荐</p>
        </div>
        <div class="recommend_content" v-for="(item,index) in List" :key="index" @click="handleClick(item.id)">
            <div class="user_avatar">
                <div class="user_info">
                    <img :src="item.avatar_url !='' ? item.avatar_url : ''" alt="" @click="getinfo(item)">
                    <ul style="margin-left: .5rem">
                        <li>{{item.name != '' ? item.name : ''}}</li>
                        <li>{{getDate(item.ctime * 1000)}}</li>
                    </ul>
                </div>
                <div class="user_btn" v-show="btnshow">
                    <!-- 待修改 -->
                    <span v-if="!item.isMyFollow">
                        <van-button icon="plus" color="#1890ff" size="mini" round plain style="margin-right:.5rem;"
                            @click="GZclick(item)" v-show="showGZ">关注
                        </van-button>
                    </span>
                    <span v-else>
                        <van-button color="#dddddd" size="mini" round style="margin-right:.5rem;" @click="GZclick(item)"
                            v-show="showGZ">已关注
                        </van-button>
                    </span>
                    <van-icon class="iconfont" class-prefix="icon" name="more" @click="copy()" />
                </div>
            </div>
            <div class="user_content">
                <div class="user_content_title">
                    <van-tag v-if="item.status === 2" plain color="#ed7190" style="margin-right: .3rem">精华</van-tag>
                    <van-tag plain type="primary" color="rgba(255, 150, 11, 0.9)" v-show="Topping"
                        :class="item.classifyInfo === null ? 'activeClass' : ''">
                        {{item.classifyInfo === null ? '' : item.classifyInfo.title}}
                    </van-tag>
                    <!-- 帖子 -->
                    <span v-if="!isActive" class="user_content_infor">{{item.title}}</span>
                    <!-- 帖子详情页面 -->
                    <span v-if="isActive" class="isActive">{{item.title}}</span>
                </div>
                <!-- 详情页图文混排 -->
                <div class="user_content_text" v-html="item.content" v-show="contentHtml" @click="viewPic">
                    {{item.content}}
                </div>
                <!-- 过滤标签 -->
                <div class="user_content_text" v-show="!contentHtml" v-html="filterTag(item.content)"></div>
                <ul v-show="!contentHtml">
                    <li v-for="(imgUrl,index) in filterImg(item.content)" :key="index" :style="{margin:screenMargin}">
                        <img :style="{width : screenWidth ,height :screenHeight}" :src="imgUrl" alt=""
                            @click="viewPic(imgUrl)">
                    </li>
                </ul>
            </div>
            <div class="action_pick" v-show="actionPick">
                <!-- 点赞 -->
                <van-icon v-if="!item.isMyNIce" class="iconfont" class-prefix="icon" name="dianzan"
                    @click.stop="goCertification(item)" />
                <van-icon v-else class="iconfont yi" class-prefix="icon" name="yidianzan"
                    @click.stop="goCertification(item)" />
                <span style="margin-right: .66rem;">{{item.nices_num}}</span>
                <!-- 评论 -->
                <van-icon class="iconfont" class-prefix="icon" name="pinglun" @click="handleClick(item.id)" />
                <span>{{item.comment_num}}</span>
                <!-- 删除 -->
                <van-icon v-show="deleteBtn" class="iconfont" class-prefix="icon" name="shanchu"
                    style="margin-left:.5rem" @click.stop="Delete(item.id)" />
            </div>
            <div class="tag" v-show="tag">
                <van-tag type="primary" color="#d1e9ff" :class="item.topicInfo === null ? 'activeClass' : ''">
                    {{item.topicInfo === null ? '' :item.topicInfo.title}}
                </van-tag>
            </div>
        </div>
        <van-overlay :show="showMask">
            <certification :showDialog='showDialog' @Close='handleClose'></certification>
        </van-overlay>

        <van-overlay :show="showMask">
            <!-- 登录 -->
            <logindialogbox :showDialog='showDialog' @putRegister='handleRegister' @Close='handleClose'>
            </logindialogbox>
            <!-- 注册 -->
            <rgisterdialogbox :showeRgister='showeRgister' @Login='handleLogin' @Close='handleClose'></rgisterdialogbox>
        </van-overlay>
    </div>
</template>
<script>
    import moment from "moment"
    import certification from '../components/certification'
    import rgisterdialogbox from '../components/registerDialogBox'
    import logindialogbox from '../components/loginDialogBox'

    export default {
        name: 'dynamic',
        props: {
            handLine: {
                type: Boolean,
                default: false
            },
            tag: {
                type: Boolean,
                default: false
            },
            Topping: {
                type: Boolean,
                default: true
            },
            List: {
                type: Array,
                default () {
                    return []
                }
            },
            imgList: {
                type: Array,
                default () {
                    return []
                }
            },
            actionPick: {
                type: Boolean,
                default: true
            },
            btnshow: {
                type: Boolean,
                default: false
            },
            contentHtml: {
                type: Boolean,
                default: false
            },
            showGZ: {
                type: Boolean,
                default: false
            },
            deleteBtn: {
                type: Boolean,
                default: false
            },
            screenWidth: {
                type: String,
                default: '6.75rem'
            },
            screenHeight: {
                type: String,
                default: '6.75rem'
            },
            screenMargin: {
                type: String,
                default: '0 0 0 .94rem'
            },
            isActive: {
                type: Boolean,
                default: false
            }
        },
        components: {
            certification,
            logindialogbox,
            rgisterdialogbox,

        },
        data() {
            return {
                showDialog: false,
                showMask: false, //遮罩层
                isMygz: 0, //未关注
                showeRgister: false,
            }
        },
        mounted() {

        },
        methods: {
            /**注册 */
            handleRegister() {
                this.showeRgister = !this.showeRgister
            },
            /**关闭弹窗 */
            handleClose() {
                this.showDialog = false
                this.showeRgister = false
                this.showMask = false
            },
            /**登录 */
            handleLogin() {
                this.showeRgister = !this.showeRgister
                this.showDialog = true
            },
            /**实名认证 点赞*/
            goCertification(item) {
                // this.showDialog = true
                // this.showMask = true
                this.$post('/api/createPostsRelation', {
                        postId: item.id,
                        type: 2
                    })
                    .then((response) => {
                        if (response.msg === 'success') {
                            response.data == '-1' ? item.nices_num += 1 : item.nices_num -= 1
                            response.data == '-1' ? item.isMyNIce = 1 : item.isMyNIce = 0
                            this.$toast(response.data == '-1' ? '点赞成功' : '取消点赞')
                        } else {
                            /**登录过期的时候调起登录弹窗*/
                            this.showDialog = true
                            this.showMask = true
                        }
                    })
                    .catch((error) => {
                        console.log('----error', error)
                    })
                // console.log('--item',item)
            },
            /**关闭弹窗 */
            handleClose() {
                this.showMask = false
                this.showDialog = false
            },
            getDate(time) {
                return moment(time).format("YYYY-MM-DD");
            },
            handleClick(id) {
                this.$emit('putItem', id)
            },
            getinfo(item) {
                this.$emit('putInfo', item)
            },
            GZclick(item) {
                this.$emit('putGZ', item)
            },
            Delete(item) {
                this.$emit('putDelete', item)
            },
            viewPic() {
                if (event.target.className === 'image' || event.target.className === 'rich-text-img') {
                    let url = event.target.src
                    this.$emit('putUrl', url)
                }
            },
            copy() {
                this.$emit('putCopy')
            },
            /**过滤标签*/
            filterTag(domString) {
                return domString.replace(/<[^>]+>/g, '');
            },
            filterImg(domString) {
                try {
                    var arr = domString.match(/(https?:[^:<>"]*\/)([^:<>"]*)(\.((png!thumbnail)|(bmp)|(png)|(apng)|(jpeg)|(jpg)|(webp)|(gif)))/g);
                } catch (error) {
                    return [];
                }
                return arr;
            }
        }
    }
</script>
<style scoped>
    .recommend {
        width: 100%;
        height: auto;
        /* height: 100%; */
        background-color: #ffffff;
        /* padding-bottom: 3rem; */
    }

    .recommend_content {
        position: relative;
        width: 100%;
        height: auto;
        margin: 0 auto;
        padding: 1rem 0;
    }

    .recommend_content::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 95%;
        height: .03rem;
        background-color: #dddddd;
        margin: 0 .94rem;
    }

    .information_title {
        border-bottom: .03rem solid #dddddd;
        margin: 0 .94rem;
    }

    .information_text {
        display: inline-block;
        text-indent: .38rem;
        font-weight: bold;
        font-size: 1.06rem;
        border-left: .25rem solid #1890ff;
    }

    .user_avatar {
        display: flex;
        justify-content: space-between;
        margin: 0 .94rem;
    }

    .user_info {
        display: flex;
        justify-content: flex-start;
    }

    .user_btn {
        display: flex;
        align-items: center;
        margin-left: .5rem;
    }

    .user_avatar img {
        width: 1.75rem;
        height: 1.75rem;
        border: solid 0.06rem #ffffff;
        border-radius: 50%;
    }

    .recommend_content ul {
        color: black;
        font-size: 0.63rem;
    }

    .recommend_content ul li:last-child {
        color: #999999;
    }

    .user_content {
        margin: .38rem auto;

    }

    .user_content_title {
        display: flex;
        align-items: center;
        margin-left: .94rem;
    }

    .user_content_infor {
        display: inline-block;
        max-width: 17.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 600;
        margin-left: .3rem;
    }

    .user_content_text {
        word-break: break-all;
        font-size: .81rem;
        color: #494949;
        /* margin-left: .94rem; */
        margin: 0 .94rem;
        padding: .38rem 0 0 0;
        white-space: pre-wrap;
        /* 多行文本溢出显示省略号 */
        /* display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden; */
    }

    .user_content img {
        /* width: 6.75rem;
        height: 6.75rem; */
        object-fit: cover;
        border-radius: 0.31rem;
        background-size: cover;
    }

    .user_content ul {
        /* width: 100%;
        height: auto; */
        padding: .38rem 0 0 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
    }

    /* .user_content ul li {
        margin: 0 0 0 .94rem;
    } */

    .action_pick {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: .94rem;
    }

    .tag>>>.van-tag {
        color: #67b6ff;
        font-size: 0.81rem;
        padding: 0 .63rem;
        line-height: 1.38rem;
        margin-left: .94rem;
    }

    .activeClass {
        display: none;
    }

    .action_pick img {
        width: 1.25rem;
        height: 1.06rem;
    }

    .yi {
        color: #1890ff;
    }

    .isActive {
        display: inline-block;
        height: auto;
        /* margin-top: 1rem; */
        max-width: 18.75rem;
        font-size: 1rem;
        font-weight: 600;
        margin-left: .3rem;
    }

    .user_content_text>>>.image,
    .user_content_text>>>img {
        margin: .5rem 0;
        display: inline-block;
        width: 100%;
        height: auto;
        flex-shrink: 0;
    }
</style>