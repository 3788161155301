import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/assets/iconfont/iconfont.css'
import store from './store/index'
import moment from 'moment'

import {
  post,
  get,
  postImg
}
from '../src/request/httprequest'

import {
  Toast
} from 'vant';
import {
  Dialog
} from 'vant'

// import VConsole from 'vconsole';
// var vConsole = new VConsole();


Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Vant);

Vue.config.productionTip = false

Vue.prototype.$moment = moment

// 定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$postImg = postImg;

moment.locale('zh-cn')

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')