<template>
    <div class="box">
        <div class="user_avatar" v-for="(item,index) in list" :key="index">
            <!-- 聊天列表 -->
            <div class="user_avatar_left" @click="handleClick(item)">
                <img :src="item.avatarUrl != '' ? item.avatarUrl : ''" alt="">
                <ul>
                    <li>
                        <span style="color:#1890ff;">{{item.name != '' ? item.name : ''}}</span>
                    </li>
                    <li class="dianzan">
                        <span>点赞了</span>
                        <span>{{item.type == 1 ? '我的帖子' : '我的评论'}}：</span>
                        {{item.content}}
                    </li>
                </ul>
            </div>
            <span class="time">{{getDate(item.time * 1000)}}</span>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'
    export default {
        name: 'niceList',
        props: {
            list: {
                type: Array,
                default () {
                    return []
                }
            },
        },
        data() {
            return {

            }
        },
        methods: {
            handleClick(item) {
                this.$emit('putItem', item)
            },
            getDate(item) {
                return moment(item).format('MM-DD');
            }
        }
    }
</script>
<style scoped>
    .user_avatar:first-child {
        padding-top: 1rem;
    }

    .user_avatar {
        width: 100%;
        height: 3.16rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: .03rem solid #dddddd;
        background-color: #ffffff;
    }

    .user_avatar_left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: .94rem;
    }

    .user_avatar ul {
        padding: 0;
        margin-left: .75rem;
    }

    .dianzan {
        height: 2rem;
        width: 14rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .user_avatar ul li:first-child {
        font-size: 0.81rem;
        color: #494949;
    }

    .user_avatar ul li:last-child,
    .time {
        font-size: 0.81rem;
        color: #999999;
    }

    .time {
        padding-right: .94rem;
    }

    /* .text {
        display: inline-block;
        margin-left: .5rem;
    } */

    .user_avatar img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
    }
</style>