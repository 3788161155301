import { render, staticRenderFns } from "./postDetail.vue?vue&type=template&id=3187cecc&scoped=true&"
import script from "./postDetail.vue?vue&type=script&lang=js&"
export * from "./postDetail.vue?vue&type=script&lang=js&"
import style0 from "./postDetail.vue?vue&type=style&index=0&id=3187cecc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3187cecc",
  null
  
)

export default component.exports