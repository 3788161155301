<template>
    <div class="box">
        <!-- 设置 -->
        <header-information title='设置' bgcolor='#ffffff' wordcolor='#494949'></header-information>

        <!-- 黑名单列表  暂时关闭-->
        <!-- <div class="contain">
            <span>黑名单列表</span>
            <van-icon name="arrow" />
        </div> -->
        <!-- 退出 -->
        <div class="btn">
            <van-button color="#dddddd" @click="signOut()">退出登录</van-button>
        </div>
    </div>
</template>
<script>
    import headerInformation from '../components/headerInformation'
    export default {
        name: 'setting',
        components: {
            headerInformation,
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {
            /**退出登录*/ 
            signOut(){
                localStorage.setItem('token','')
                localStorage.removeItem('userId')
                this.$router.push({
                    path:'/home'
                })
            }
        }
    }
</script>
<style scoped>
    .box {
        width: 100%;
        height: 2.75rem;
    }

    .contain {
        width: 100%;
        height: 2.81rem;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 .94rem;
        box-sizing: border-box;
        font-size: 0.81rem;
        color: #494949;
    }

    .btn>>>.van-button__text {
        color: #494949;
        font-size: 0.81rem;
        color: #494949;
    }

    .btn>>>.van-button--normal {
        width: 93%;
        height: 2.13rem;
        margin: 0 auto;
        display: block;
    }

    .btn {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 3rem;
    }
</style>