import { render, staticRenderFns } from "./myPhone.vue?vue&type=template&id=06eeb83c&scoped=true&"
import script from "./myPhone.vue?vue&type=script&lang=js&"
export * from "./myPhone.vue?vue&type=script&lang=js&"
import style0 from "./myPhone.vue?vue&type=style&index=0&id=06eeb83c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06eeb83c",
  null
  
)

export default component.exports